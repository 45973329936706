/* Dashboard Renderer CSS */
.dashboard-renderer {
    border: 0px solid black;
    border-bottom: 2px solid black;
    display: grid;
}
.kc-ui-custom-live-diagnostic .gridView{
    display: grid;
}
.colm12D .live-render-part {
    position: absolute;
    justify-content: center;
    margin: 3px -10px 0px -5px;
}
.colm12D .live-render-part-chart {
    position: relative;
    margin: 0px -10px 0px -3px;
}

.dashboard-renderer-span1 {
    width: 16.66%;
}

.dashboard-renderer-span1_5 {
    width: 25%;
}

.dashboard-renderer-span2 {
    width: 33.33%;
}

.dashboard-renderer-span3 {
    width: 50%;
}

.dashboard-renderer-span4 {
    width: 66.66%;
}

.dashboard-renderer-span5 {
    width: 83.33%;
}

.dashboard-renderer-span6 {
    width: 100%;
}

.kc-ui-colm {
    min-height: 154px;
    width: 100%;
}

.render-part {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px -10px 0px -5px;
}

.kc-ui-tab-header {
    padding: 10px 0px !important;
}
.kc-ui-tab-header > ul > li{
    padding: 2px 0px;
}

.kc-ui-tab-renderer {
    margin: 0px -17px 0px -3px !important;
}

.kc-ui-title-heading {
    padding: 18px 0 0px !important;
}

.dashboardRenderer .pageHeader .kc-ui-tab {
    background: #000000;
}

.kc-ui-header.kc-ui-colm {
    min-height: 65px !important;
}
.kc-ui-information-v2.kc-ui-colm,
.kc-ui-dual-list-v2.kc-ui-colm,
.kc-ui-status-v2.kc-ui-colm,
.kc-ui-icon-v2.kc-ui-colm {
    min-height: 85px;
}

.kc-ui-status.kc-ui-colm,
.kc-ui-trend.kc-ui-colm,
.kc-ui-information.kc-ui-colm {
    min-height: 170px !important;
}

.kc-ui-table.kc-ui-colm,
.kc-ui-stack.kc-ui-colm{
    min-height: 250px !important;
}
.kc-ui-line-chart.kc-ui-colm,
.kc-ui-bar-chart.kc-ui-colm {
    min-height: 350px !important;
}

.kc-ui-link.kc-ui-colm .kc-ui-colm-div h4,
.kc-ui-status.kc-ui-colm .kc-ui-colm-div h4,
.kc-ui-information.kc-ui-colm .kc-ui-colm-div h4,
.kc-ui-dual-list.kc-ui-colm .kc-ui-colm-div h4,
.kc-ui-piechart.kc-ui-colm .kc-ui-colm-div h4,
.kc-ui-bar-chart-target.kc-ui-colm .kc-ui-colm-div h4  {
    margin-top: 10px;
}

.kc-ui-table-title-header {
    position: relative;
    bottom: 10px;
}

.kc-ui-table.kc-ui-colm .kc-ui-colm-div h4,
.kc-ui-stack.kc-ui-colm .kc-ui-colm-div h4,
.kc-ui-line-chart.kc-ui-colm .kc-ui-colm-div h4,
.kc-ui-bar-chart.kc-ui-colm .kc-ui-colm-div h4 {
    margin-top: 20px;
    text-align: left;
}

.kc-ui-status.kc-ui-colm .kc-ui-colm-div .kc-ui-colmiVal {
    position: relative;
    left: 10px;
    top: 15px;
}

.kc-ui-title-div,
.kc-ui-info-wrapper {
    position: relative;
    top: 15px;
}

.kc-ui-div-dd-align {
    margin: 5px 5px;
}

.kc-ui-trend .kc-ui-dd-wrapper {
    top: -24px;
    position: relative;
}

.kc-ui-information .kc-ui-dd-wrapper {
    top: -10px;
    position: relative;
}

.kc-ui-tab-datefilter {
    margin-bottom: -20px;
}

.kc-ui-dd-flag {
    position: relative;
    bottom: 0px;
}

.kc-ui-icon,
.kc-ui-detail {
    background: #1f2124 !important;
    min-height: 115px !important;
}

.kc-ui-colm-div {
    padding: 5px 5px 0px 5px !important;
}

.kc-ui-colm-div h4 {
    margin-left: 5px;
    margin-top: -5px;
    margin-right: -70px;
}

.kc-ui-information .kc-ui-info-val {
    margin: 5px 0px 20px 0px;
}

.kc-ui-title {
    font-family: open_sanssemibold;
}

.kc-ui-info-wrapper .kc-ui-md-image {
    max-width: 100px;
    max-height: 100px;
}

.kc-ui-status .kc-ui-error-div {
    padding: 0px 0px 0px 40px;
    position: relative;
    top: -10px;
}

.kc-ui-error-div>span {
    margin-left: 30px !important;
}

.kc-ui-error-image {
    width: 75px !important;
}

.kc-ui-icon-image .kc-ui-md-image {
    max-height: 100px;
    max-width: 100px;
}

.kc-ui-icon-image .kc-ui-sm-image {
    max-height: 32px;
    max-width: 32px;
}

.kc-ui-detail .kc-ui-colm-div {
    margin-left: 5px;
}

.kc-ui-icon-align {
    top: 20px !important;
}

.kc-ui-dropdown ul {
    background-color: #f2f2f2 !important;
}

.kc-ui-dd-flag .react-datepicker-wrapper {
    margin-top: -5px
}

.kc-ui-date-filter-title {
    margin-bottom: -2px !important;
}

.kc-ui-dd-flag input[type=text]::placeholder {
    color: var(--kc-secondary-txt-color);
    font-size: 9px;
}

.kc-ui-trend-val-low,
.kc-ui-trend-val-high {
    margin-top: 10px;
}

.kc-ui-trend-time,
.kc-ui-trend-prev {
    font-size: 13px !important;
}

.kc-ui-trend-colm {
    padding: 0px !important;
}

.kc-ui-dd-to {
    margin-right: 10px;
    margin-left: 10px;
}

.kc-ui-dd-filter {
    max-width: 60px;
    text-align: left;
}

.kc-ui-dd-from {
    margin-right: 10px;
}

.dashboard-renderer .kc-ui-dd-flag .react-datepicker-wrapper input {
    background: none;
}

.kc-ui-dd-flag .react-datepicker-wrapper input {
    font-size: 11px;
}

.kc-ui-trend-wrapper {
    margin-top: 30px !important;
}

.kc-ui-trend-date {
    font-size: 12px;
    font-family: 'open_sansregular';
}

.kc-ui-information .kc-ui-info-wrapper .kc-ui-flex {
    margin-bottom: 10px;
}

.kc-ui-info-key {
    width: 45%;
}

.kc-ui-info-value,
.kc-ui-info-key {
    font-family: 'open_sansregular';
    padding: 7px 5px;
}

.kc-ui-header-info>ul>li {
    padding: 20px 20px;
}

.kc-ui-table-widget>ul.tHead li {
    font-size: 14px;
}

.kc-ui-dual-list-div {
    position: relative;
    top: 25px;
    left: 10px;
}

.kc-ui-legend {
    margin-bottom: 20px;
    margin-left: 45px;
}

.kc-ui-link-label {
    margin: 10px 0px;
}

.kc-ui-table .kc-ui-colm-div {
    padding: 0px !important;
}

.dashboard-renderer .colmDi h4 {
    left: -15px;
    position: relative;
}

.dashboard-renderer .colmDi h4 button {
    right: -10px;
    position: relative;
}

.kc-ui-icon .kc-ui-widget-loader{
    margin: 15px 0px;
}


.kc-ui-information-v2 .kc-ui-widget-loader,
.kc-ui-dual-list-v2 .kc-ui-widget-loader,
.kc-ui-status-v2 .kc-ui-widget-loader,
.kc-ui-icon-v2 .kc-ui-widget-loader {
    margin: 20px 0px;
}

.kc-ui-status .kc-ui-widget-loader,
.kc-ui-trend .kc-ui-widget-loader,
.kc-ui-information .kc-ui-widget-loader,
.kc-ui-link .kc-ui-widget-loader,
.kc-ui-dual-list .kc-ui-widget-loader {
    position: relative;
    top: 25px;
    margin: 20px 0px 0px 0px;
}

.kc-ui-table .kc-ui-widget-loader,
.kc-ui-stack .kc-ui-widget-loader,
.kc-ui-line-chart .kc-ui-widget-loader,
.kc-ui-bar-chart .kc-ui-widget-loader  {
    position: relative;
    top: 50px;
    margin: 20px 0px 0px 0px;
}
.kc-ui-table-progress{
    padding: 0 0 0 10px !important;
}

.kc-ui-trend-value .kc-trend-time-specifiers {
    font-size: 32px;
}
.dashboard-livediagnostic-tab .kc-ui-table {
    min-height: 180px !important;
    max-height: 195px !important;
}

.dashboard-livediagnostic-tab .kc-ui-table-wrapper{
    padding: 0px;
}

.dashboard-livediagnostic-tab .kc-ui-table .kc-ui-table-widget {
    margin-top: 11px;
}

.dashboard-livediagnostic-tab .kc-ui-table .kc-ui-table-widget>ul.tHead li {
    padding: 5px 0px;
}

.dashboard-livediagnostic-tab .kc-ui-table .kc-ui-table-widget>ul>li.kc-ui-table-li {
    padding: 4px 0px;
}

.dashboard-livediagnostic-tab .kc-ui-table .kc-ui-table-progress{
    padding: 0px !important;
}
.kc-ui-custom-live-diagnostic-grid {
    min-height: 220px;
    position: relative;
}

.kc-ui-custom-live-diagnostic {
    justify-content: center;
    text-align: center;
}

.kc-ui-icon-v2 .kc-ui-device-no-message{
    padding: 10px 0px;
}

.kc-ui-custom-live-modal-wrap {
    background-color: #000000D4;;
    min-height: 100%;
    position:absolute;
    top: -55px;
    left: -2px;
    right:0px;
    bottom:0px;
    padding-top: 1%;
}

.kc-ui-custom-live-modal-wrap-,
.kc-ui-custom-live-modal-wrap-undefined,
.kc-ui-custom-live-modal-wrap-SessionSuccess,
.kc-ui-custom-live-modal-wrap-SessionWarningNoPopup {
    position: inherit;
}

.kc-ui-custom-live-visible {
    pointer-events: auto;
    opacity: 1;
    transition: all 300ms ease-in-out;
}

.kc-ui-custom-unit-status {
    background-color: white;
    color: black;
    display: inline-grid;
    justify-content: center;
    text-align: center;
    width: 610px;
    height:277px;
    border-radius: 6px;
}
.kc-ui-custom-unit-offline-status{
     background-color: white;
     width: 610px;
     height: 207px;
     top: 3%;
     border-radius: 6px;
     display: inline-grid; 
}
.kc-ui-custom-unit-session-warninig-status{
    background-color: white;
    color: #253465;
    display: inline-grid;
    text-align: center;
    width: 610px;
    height:329px;
    border-radius: 6px;
}
.kc-ui-custom-unit-session-warninig-status h5{
    color: #253465;
    font-size: 20px;
    font-weight: 700;
}
.kc-ui-custom-unit-session-warninig-status p{
    color: #253465;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}
.kc-ui-custom-establish-bottom{
    margin: 14px 0px 11px 0px ;
}
.kc-ui-custom-establish-doube-text{
    margin: -10px 0px -3px 0px;
}
.kc-ui-custom-establish-doube-text-bottom{
    margin: 4px 0px -40px 0px;
}
.custom-cancel-div {
    background-color: white;
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 6px;
}

.custom-cancel-image {
    color:#0D3968;
    width: 24px;
    height: 24px;
    float: right;
    border-radius: 20px;
}

.kc-ui-custom-unit-status .kc-ui-custom-session-button {
    margin-bottom: 15px;
}

.kc-ui-custom-unit-status p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
    color: #253465;
    margin-top: 10px;
}

.kc-ui-custom-unit-status h5 {
    color: black;
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: bold;
}

.kc-ui-custom-unit-status h3 {
    color: #253465;
    margin-bottom: 14px;
    margin-top: 25px;
    font-weight: 700;
    font-size: 20px;

}

.kc-ui-custom-live-session-image {
    width: 20px;
    height: 20px;
}

.kc-ui-custom-live-header-text {
    width: 89%;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: bold;
}
.kc-ui-custom-multi-user-live{
    background: #6E87A2;
    float: right;
    padding: 5px;
    border-radius: 5px;
    width: 210px;
    margin-top: 3px;
}
.kc-ui-custom-live-header {
    background-color: #1FCB4F;
    float: right;
    padding: 5px;
    border-radius: 5px;
    width: 210px;
    margin-top: 3px;
}
.kc-ui-custom-live-warning-header {
    background-color: #2E2F34;
    float: right;
    padding: 5px;
    border-radius: 5px;
    width: 245px;
    color: #E54755;
}
.kc-ui-custom-unit-image {
    width: 65px;
    height: 65px;
    display: block;
    margin: auto;
    margin-top: 30px;
}
.kc-ui-custom-unit-liveMonitoringExpired-image{
    width: 65.19px;
    height: 65.19px;
    display: block;
    margin: auto;
    margin-top: -57px;
}
.kc-ui-custom-unit-live-monitor-image{
    width: 65.19px;
    height: 65.19px;
    display: block;
    margin: auto;
    margin-top: 25px;
}
.kc-ui-custom-unit-offline-image{
    width: 65.19px;
    height: 52.93px;
    display: block;
    margin: auto;
}
.kc-ui-custom-unit-offline-status h3{
    color: #253465;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: 700;
    font-size: 20px;
}
.kc-ui-custom-unit-offline-status p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
    color: #253465;
}
.kc-ui-custom-tooltip-image{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.kc-ui-custom-tooltip-text{
    color: #1A1C22; 
    font-weight: bold;
}
.kc-ui-custom-unit-status .kc-ui-custom-connection-progress{
    background: #c5c5c5;
    border-radius: 5px;
    display: contents;
    width: 138.68px;
    height: 6px;
    position: relative;
    top: 56.59px;
}
.kc-ui-custom-unit-status .kc-ui-custom-connection-progress .progress-bar{
    background-color: #ffbe00;
    width: 25%;
}
.kc-ui-custom-unit-status .kc-ui-custom-inline{
    display: inline-flex;
}
.kc-ui-device-status-text{
    font-size: 13px;
}
.kc-ui-custom-button {
    color: white;
    background: #253465;
    padding: 10px 12px 10px 12px;
    float: left;
    font-size: 16px;
    height: 42px;
    width: 323px;
    display: block;
    border-radius: 100px;
    margin: auto;
}
.kc-ui-custom-unit-status p.kc-ui-custom-establish-doube-text{
    margin:10px 100px 0px 100px;
}
.kc-ui-custom-unit-status p.kc-ui-custom-establish-bottom{
    margin:10px 0px 0px 0px;
}
.kc-ui-custom-button:hover {
    color: white;
}
.kc-ui-legend-div{
    margin-bottom: 20px;
}
.kc-ui-custom-live-button,
.kc-ui-custom-cancel-button,
.kc-ui-custom-session-success {
    margin-top: 25px;
    margin-bottom: 20px;
}

.kc-ui-success-image {
    width: 50px;
    height: 50px;
}
.kc-ui-custom-restart{
    color: white;
    cursor: pointer;
    margin-left: 10px;
    display: inline;
    text-decoration: underline;
}


/* offlinepop css */
.kc-offline-popup-main{
    background-color: rgba(0, 0, 0, 0.831372549);
    position: absolute;
    top: 150px;
    left: -2px;
    right: -2px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.kc-offline-popup-popup{
    box-sizing: border-box;
    background-color: white;
    width: 610px;
    height: 249px;
    position: absolute;
    top: 3%;
    border-radius: 6px;
}
.kc-offline-popup-popup-header{
    text-align: center;
}
.kc-wifi-image{
    width: 68px;
    height: 56.32px;
}
.kc-offline-popup-popup .customCheckbox{
    background: url("../images/icons/whiteCheckbox.svg") no-repeat left center;
	background-size: 20px 20px;
    margin: -6px 15px;    
}
.kc-offline-popup-popup .customCheckbox input[type=checkbox]:checked{
	background-size: 20px 20px;    
}
.kc-offline-popup-signal-lost{
    display: flex;
    justify-content: center;
}
.kc-offline-popup-right_side{
    text-align: center;
}
.kc-offline-popup-right_side b{
    color:  #253465;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
}
.data-display p{
    color:#AF2749;
    width:361px;
    height: 52px;
    font-size: 16px;
    font-weight: 400;
    margin:14px 0px 0px 125px;
}
#checkmessage{
    padding: 15px;
}

@media only screen and (min-width: 320px) and (max-width: 568px) {

    .dashboard-renderer-span1,
    .dashboard-renderer-span2,
    .dashboard-renderer-span3,
    .dashboard-renderer-span4,
    .dashboard-renderer-span5 {
        width: 100%;
    }
}

@media only screen and (min-width: 568px) and (max-width: 1023px) {
    .dashboard-renderer-span2 {
        width: 50%;
    }

    .kc-ui-error-div>span {
        padding: 40px 0px 50px 20px;
    }

    .kc-ui-info-key {
        width: 35%;
    }

    .kc-ui-status.kc-ui-colm .kc-ui-colm-div .kc-ui-colmiVal {
        left: 0px;
    }

    .kc-ui-colm-div h4 {
        margin-right: -100px;
    }
}
.kc-ui-trendhintmessage-div{
    position: relative;
    top: -10px !important;
    left: 20px !important;
}
.refresh {
    padding: 1px 5px !important;
}

.kc-ui-table-image {
    width: 20px !important;
    height: 20px !important;
}
.customGridView{
    border-left: 2.5px solid black;
}