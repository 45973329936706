.snackbar {
    visibility: hidden;
    min-width: 300px;
    background-color: #4CAF50;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    right: 30px;
    bottom: 40px;
    font-size: 18px;
    font-weight: 'bold';
}
.snackbarError {
    visibility: hidden;
    min-width: 250px;
    background-color: red;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    right: 30px;
    bottom: 40px;
    font-size: 18px;
    font-weight: 'bold';
}

.snackbar.show {
    visibility: visible;
    animation: slideIn 1s ease-in-out;
}

.closeIcon{
    margin-left: 20px;
    cursor: pointer;
}
.startIcon{
    margin-right: 15px;
}

/* defining the slide-in animation for snackbar */
@keyframes slideIn {
    from {
       bottom: -100px;
       opacity: 0;
    }
    to {
       bottom: 20px;
       opacity: 1;
    }
 }