@media only screen and (min-width: 320px) and (max-width: 369px) {
	.navUserWrapper{
		width: 90%;
	}
	.navbarLeft.fivePillarsHeader > li .navStoreText {
		width: 144px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	} 
	.mainMenuGrid > ul > li,
	.allLocationsGrid > ul > li,
	.allUnitsGrid > ul > li {
		width: 100%;
	}
	.gridView .colm3D {
		float: left;
		width: 100%;
	}
	.subscriptionList > ul.tHead > li:nth-child(1) { 
		font-size: 13px; 
	}
	.navbarRight > li > a.navFilter, .navbarRight > li > a.navFilter,
	.dragAndBrowse > ul > li .drogAndDroporBrowse,
	.bg-Welbilt .navbarRight > li > a.navBack {
		display: none !important;
	}
	.multiLanguage .languageSelection { 
		padding:3px 5px;
		width:60px; 
	}
	.multiLanguage {
		margin: 15px 0px;
	}
	.multiLanguage .dropdown-menu{
		right: 0px;
	}
	.subscriptionList > ul.tHead > li:nth-child(1) { 
		font-size: 14px; 
	}
	.navbarRight > li > a.navBack,  
	.navbarRight > li > a.navFilter, 
	.navbarRight > li > a.navSearch, 
	.navbarRight > li > a.navAlarm, 
	.navbarRight > li > a.navUser {
		width: 42px; 
	}
}
@media only screen and (min-width: 370px) and (max-width: 420px) { 
	.navUserWrapper{
		width: 90%;
	}
	.navbarLeft.fivePillarsHeader > li .navStoreText {
		width: 154px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.multiLanguage .languageSelection { 
		padding:3px 5px;
		width:60px; 
	}
	.multiLanguage {
		margin: 15px 0px;
	}
	.multiLanguage .dropdown-menu{
		right: 0px;
	}
	.bg-Welbilt .navbarRight > li > a.navBack,
	.navbarRight > li > a.navFilter, .navbarRight > li > a.navFilter{
		display:none !important;
	} 
	.userProfile > h4 {
		font-size: 24px;
	}
	.userProfile .userDetails .userNameRole .userName {
		font-size: 18px;
	}
	.userProfile ul.userDetailsList > li {
		font-size: 16px;
	}
	.userProfile .userDetails .userNameRole .userRole,
	.subscriptionList > ul.tHead > li:nth-child(1) { 
		font-size: 14px; 
	}
	.navbarRight > li > a.navBack,  
	.navbarRight > li > a.navFilter, 
	.navbarRight > li > a.navSearch, 
	.navbarRight > li > a.navAlarm, 
	.navbarRight > li > a.navUser {
		width: 42px; 
	} 
	.gridView .colmDi > h4 .dropdownFilterSM {
		float: right;
		clear: both;
	}
	.gridView .colm3D {
		float: left;
		width: 100%;
	}
	.gridViewTable {
		padding: 0px 2px;
	}
	.gridView .colm2D,
	.gridView .colm3D,
	.gridView .colm4D,
	.gridView .colm6D,
	.gridView .colm7D,
	.gridView .colm8D,
	.gridView .colm9D,
	.gridView .colm10D,
	.gridView .colm12D {
		border-left: 0px;
		border-right: 0px;
	} 
	.allUnitsGrid > ul > li {
		width: 100%;
	}
	.filterPagination > ul > li,
	.paginationFooter > ul > li {
		font-size: 12px;
		padding: 13px 10px;
	}
	.navUserWrapper {
		width: 280px;
	}
	.userProfile {
		padding: 15px 15px 0px;
	}
	.userProfile .userDetails .userProfileImg {
		width: 44px;
		height: 44px;
		margin-right: 5px;
	}
	.userProfile .userDetails .userProfileImg > img {
		max-width: 44px;
	}
	.userProfile .userDetails .userNameRole {
		width: 188px;
	}
	.userProfile ul.userDetailsList {
		padding: 15px 0px 0px 0px;
	}
	.dragAndBrowse > ul > li .drogAndDroporBrowse{
		display:none;
	}
}
@media only screen and (min-width: 320px) and (max-width: 480px) {  
	.confirmMContententD button.close{
		right: 0px;
    	top: 0px;
	}
	.confirmMContententD .h3Title{
		padding: 10px 20px 0px;
	}
	.confirmModalSM .modal-footer .confirmnBtn{
		min-width:280px;
	}
	.accountSubscription{
		padding: 35px 25px;
	}
	.timeNumberFormatForm{
		padding: 0px 15px;
	}
	.timeNFTable > ul > li:nth-child(1){
		width: auto;
	}
	.NotifnMgmtTable > ul > li .input-group{
		width: 110px;
	}
	.NotifnMgmtTable > ul > li:nth-child(3),
	.timeNFTable > ul > li:nth-child(2){
		width: 100px;
	} 
	.timeNFTable > ul > li:nth-child(3){
		width: 160px;
	}
	.stConfirmBox > ul > li,
	.uploadedUnitsList.samrtTagentrys > ul > li,
	.uploadedUnitsList > ul > li{
		width:50%;
		float:left;
	}
	.stConfirmBox .stDataD,
	.stConfirmBox .unitsWithST, 
	.stConfirmBox .unitsWithoutST, 
	.stConfirmBox .errorsST,
	.uploadedUnitsList .registeredDevices,
	.uploadedUnitsList .existingDevices,
	.uploadedUnitsList .duplicateEntry,
	.uploadedUnitsList .wrongEntry,
	.uploadedUnitsList .removedEntry { 
		width:100%;
		height:auto;
		border-radius:0;
		padding:5px;  
	}
	.stConfirmBox,
	.uploadedUnitsList {
		width:100%;
		display: table;
		margin:0px 0px;
	}
	.rcNotesFormField.addedImage {
		width: 82%; 
	}
	.menuBrandsTable > ul > li:nth-child(2) {  
		padding-left: 10px;
	}
	.menuBrandsTable > ul > li:nth-child(2) > img {
		max-width: 280px;
	}
	.subscriptionPlanPro h2,
    .subscriptionPlanBasic h2{
        padding: 20px 0px 0px;
        font-size: 16px;
    }
	.rcSettingsTable > ul > li{
		width: 50%;
		float: left;
		margin-bottom:5px;
	}
	.navbar-dark .navbar-nav.titlebarLeft .nav-link {
		width:120px;
		font-size: 14px;  
	}
	.mainMenuGrid > ul > li,
	.allLocationsGrid > ul > li,
	.allUnitsGrid > ul > li {
		width: 50%;
	}
	.kcHowToTable > ul > li { 
		padding:0px 0px 20px 0px; 
		width:100%;
		float:left;
	}
	.kcHowToGridOuter { 
		padding: 0px 15px; 
	}
	.menuFilterHeader > ul > li .input-group {
		max-width: 280px;
	}
	.menuAStatusExpTable > ul > li:nth-child(3),
	.menuAStatusExpTable > ul > li:nth-child(3) h4,
	.menuAStatusExpTable > ul > li:nth-child(3) h5 { 
		font-size:14px;
	}
	
	.menuAStatusExpTable .menuAExpTable > ul > li h4, 
	.menuAStatusExpTable .menuAExpTable > ul > li h5 { 
		width: 160px;
	} 
	.loginButton > ul > li .loginIWrapper {
		width: 100%;
	}
	.loginButton > ul > li {
		padding: 5px 0px;
	}
	.loginButton > ul > li:nth-child(1),
	.loginButton > ul > li:nth-child(3) {
		width: 15px;
	}
	.loginButton > ul > li:nth-child(2) {
		width: auto;
	}
	.SignInSuccessModal .singINConfirmation {
		padding: 0px 0px 10px;
	}
	.modal-dialog.modal-md {
		width: 100%;
		padding: 0px 15px;
		margin: 0px;
	}
	.modal-body.lgoinModalBody {
		padding: 0px 0px 15px;
	}
	.loginModalHeader {
		padding: 15px;
	}
	.existingLoginForm > ul > li .forgotpassword,
	.existingLoginForm > ul > li .customCheckbox label {
		font-size: 14px;
	}
	.navbarLeft > li{
		border-right: 0px;
		width: 160px;
		min-width: 120px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	} 
	.multiLanguage {
		margin: 15px 5px;
	}
	.navbarLeft > li .navStoreIcon {
		padding: 0px 5px 0px 0px;
		margin-right:5px;
	}
	.selectedNavbarLeft > li {
		width: 170px;
		padding: 10px 0px 9px 5px;
	}
	.selectedNavbarLeft > li .notificaMgmtNav,
	.selectedNavbarLeft > li .helpNav,
	.selectedNavbarLeft > li .UnitRegistrationNav,
	.selectedNavbarLeft > li .billingPortalNav,
	.selectedNavbarLeft > li .subscriptionNav,
	.selectedNavbarLeft > li .UserManagementNav,
	.selectedNavbarLeft > li .errorCenterNav,
	.selectedNavbarLeft > li .swaggerNav,
	.selectedNavbarLeft > li .UserProfileNav,
	.selectedNavbarLeft > li .softwareNav,
	.selectedNavbarLeft > li .menuNav,
	.selectedNavbarLeft > li .recipeNav,
	.selectedNavbarLeft > li .settingsNav,
	.selectedNavbarLeft > li .mediaNav,
	.selectedNavbarLeft > li .reportNav,
	.selectedNavbarLeft > li .haccpNav,
	.selectedNavbarLeft > li .orgznManagementNav,
	.selectedNavbarLeft > li .smartTagNav {
		width: 100%;
		float: left;
		padding: 1px 5px 1px 45px;
		font-size: 14px;
		max-height: 45px;
		overflow: hidden;
		text-align:left;
	}
	.addUserForm,
	.userMgmtHeader,
	.userMgmtHeader > ul,
	.unitRegForm,
	.unitRegForm.bulkUploadForm,
	.dragAndBrowse,
	.reportHeader,
	.reportHeader > ul,
	.reportHeader > ul > li .unitRegHeader,
	.unitRegHeader > ul,
	.unitRegHeader > ul > li .reportHeader,
	.reportHeader > ul,
	.reportHeader > ul > li {
		width: 100%;
		float: left;
	} 
	.unitRegForm > ul{
		padding:10px 0px 0px;
	}
	.dragAndBrowse > ul > li{
		font-size:16px;
	}
	.reportHeader > ul > li:nth-child(1),
	.unitRegHeader > ul > li:nth-child(1) {
		text-align: center;
		width: 100%;
		float: left;
		padding: 8px 15px;
	}
	.userMgmtHeader > ul > li:nth-child(1){
		text-align: center;
		width: 100%;
		float: left; 
	}
	.userMgmtHeader > ul > li:nth-child(2),
	.reportHeader > ul > li:nth-child(2),
	.unitRegHeader > ul > li:nth-child(2) {
		border-top: 1px solid #7e8690;
		text-align: right;
		width: 100%;
		float: left;
		padding: 8px 15px;
	}
	.reportHeader > ul > li:nth-child(2),
	.unitRegHeader > ul > li:nth-child(2) {
		border-top: 1px solid #7e8690;
		text-align: right;
	}
	.addUnitHeader > ul > li:nth-child(2) {
		font-size: 18px;
	}
	.menuLibraryTable > ul.tBody > li .menuSListAS,
	.menuLbryMcfTable > ul.tBody > li .menuSListAS {
		display: block;
	}  
	.orgPurposeTable > ul > li:nth-child(2){
		padding-left:10px;
		width:45%;
	}
	.stLocationTable > ul > li:nth-child(4){
		width:120px;
	}
	.stLocationTable > ul > li:nth-child(3),
	.allErrorListTable.allHErrorListTable > ul > li:nth-child(12),
	.orgPurposeTable > ul > li:nth-child(1), 
	.menuBrandsTable > ul > li:nth-child(1),
	.menuAStatusExpTable > ul > li:nth-child(1),
	.menuAStatusTable > ul > li:nth-child(12),
	.menuLibraryTable > ul > li:nth-child(9),
	.menuLbryMcfTable > ul > li:nth-child(8),
	.unitQualityPrncTable > ul > li:nth-child(2),
	.unitQualityPrncExpTable > ul > li:nth-child(2),
	.unitQualityPrncTable > ul > li:nth-child(3),
	.unitQualityPrncExpTable > ul > li:nth-child(3),
	.unitQualityPrncTable > ul > li:nth-child(4),
	.unitQualityPrncExpTable > ul > li:nth-child(4),
	.unitQualityPrncTable > ul > li:nth-child(6),
	.unitQualityPrncExpTable > ul > li:nth-child(6),
	.userMgmtListTable > ul > li:nth-child(1), 
	.userMgmtListTable > ul > li:nth-child(4),
	.userMgmtListTable > ul > li:nth-child(5),
	.userMgmtListTable > ul > li:nth-child(6),
	.userMgmtListTable > ul > li:nth-child(7),
	.userMgmtListTable > ul > li:nth-child(8),
	.userMgmtListTable > ul > li:nth-child(9)  {
		display: none;
	}
	.unitQualityPrncTable > ul > li:nth-child(5),
	.unitQualityPrncExpTable > ul > li:nth-child(5) {
		width: 110px;
		padding-left: 0px;
		padding-right: 0px;
	}
	.selectDeviceGrid > ul > li,
	.selectModelGrid > ul > li {
		width: 46%;
	} 
}
@media only screen and (min-width: 320px) and (max-width: 568px) { 
	.toasMsgAlert{
		width: 96%;
		right: 2%;
	}
	.paymentForm > ul > li.colm50.firstLastName{
		width: 100%;
		float: left;
		margin: 20px 0px;
	}
	.termsPricingConditionsT{
		border: 1px solid #aab4be;
	}
	.termsPricingConditionsT > ul > li:last-child{
		border-right: 0px;
		border-bottom: 0px;
	}
	.termsPricingConditionsT > ul > li .tTitle {
		display: block;
	} 
	.termsPricingConditionsT > ul > li {
		width: 100%;
		float: left;
		border-left: 0px;
		border-top: 0px;
	} 
	.confirmModalSM .modal-dialog.modal-md{
		width: 100%;
		max-width: 100%;
	}
	.confirmModalSM .modal-footer .confirmNo,
	.confirmModalSM .modal-footer .confirmYes{
		min-width: 150px;
	}
	.accountSubscription .turnOnOffSBtn,
	.accountSubscription .turnOnSBtn{
		float: left;
		margin-top: 15px;
	}
	.userProfileHeader,
	.tableSMViewW,
	.planTableView {
		display: flex; 
		overflow: auto;
		white-space: nowrap;
		width: 100%;
	}
	.userProfileHeader::-webkit-scrollbar,
	.tableSMViewW::-webkit-scrollbar,
	.planTableView::-webkit-scrollbar {
		display: none;
	}
	.tableSMViewW {
		width: 94%;
	}
	.pageNotes{
		white-space: initial;
	}
	.planTableView{
		box-shadow: inherit;
		background: inherit;
		padding: 0px;
		border-radius: 0px;
	}
	.tableSMViewW{
		box-shadow: inherit;
		padding: 0px;
		border-radius: 0px;
	}
	.planTableT{
		border-radius: 0px;
	}
	.descriptionEditV > ul > li.menuPictureLi{
		width: 90px;
	}
	.allRecipeListModal > ul > li:nth-child(1),
	.allRecipeListModal > ul > li:nth-child(2),
	.orgNodeModalDiv > ul > li:nth-child(1),
	.orgNodeModalDiv > ul > li:nth-child(2),
	.unitRegForm > ul > li > label.fWeightB,
	.unitRegForm > ul > li .valueR,
	.unitRegForm > ul > li .labelL {
		width:100% !important; 
		float:left; 
		padding:0px 10px;
	}
	.STComfmtList > ul > li:nth-child(4){
		width: auto;
	}
	.stLocationTable > ul > li:nth-child(5){
		width:80px;
	}
	.stLocationTable > ul.tHead > li:nth-child(4),
	.stLocationTable > ul.tHead > li:nth-child(5){
		text-indent:-9999px;
	}
	.bakingPlansList > ul.tHead > li:nth-child(5) {
		width: 130px;
		text-align: center;
	}
	.bakingPlansList > ul > li:nth-child(5){
		width: 118px;	
	}
	.STUplaodForm > ul > li:nth-child(1),
	.STUplaodForm > ul > li:nth-child(2),
	.STUplaodForm > ul > li:nth-child(3){
		width: 100%;
		float: left; 
	} 
	.createdEditedDetailsO > ul > li,
	.STUplaodForm,
	.convolLinkTable > ul > li:nth-child(1),
	.convolLinkTable > ul > li:nth-child(2){
		width: 100%;
		float: left;
	}
	.existingLoginForm > ul > li .form-control {
		font-size: 18px;
	} 
	.garlandPrctTable > ul > li:nth-child(6), 
	.garlandPrctExpTable > ul > li:nth-child(6) {
		width: 80px; 
	}
	.stLocationTable > ul > li:nth-child(1){
		width:auto;
	}  
	.stLocationTable > ul > li:nth-child(4) {
		width: 50px;
	}
	.mediaStatus > ul > li:nth-child(4){
		width:160px;
	}
	.termsPricingConditionsT > ul.tHead,
	.createdEditedDetailsO > ul.tHead li,
	.stLocationTable > ul > li:nth-child(3),
	.bakingPlansList > ul > li:nth-child(2),
	.bakingPlansList > ul > li:nth-child(3),
	.bakingPlansList > ul > li:nth-child(4),
	.stLocationTable > ul > li:nth-child(2), 
	.garlandPrctTable > ul > li:nth-child(3), 
	.garlandPrctExpTable > ul > li:nth-child(3), 
	.garlandPrctTable > ul > li:nth-child(4), 
	.garlandPrctExpTable > ul > li:nth-child(4),  
	.SWuStatusTable > ul > li:nth-child(2),  
	.SWuStatusTable > ul > li:nth-child(4),
	.SWuStatusTable > ul > li:nth-child(5),
	.SWuStatusTable > ul > li:nth-child(7),
	.SWuStatusTable > ul > li:nth-child(8),
	.SWuStatusTable > ul > li:nth-child(9),
	.SWuStatusTable > ul > li:nth-child(10),
	.SWuStatusTable > ul > li:nth-child(11),
	.SWuStatusTable > ul > li:nth-child(12),
	.SWuStatusTable > ul > li:nth-child(14),
	.allErrorListTable > ul > li:nth-child(2),
	.allErrorListTable > ul > li:nth-child(5),
	.allErrorListTable > ul > li:nth-child(7),
	.allErrorListTable > ul > li:nth-child(8),
	.allErrorListTable > ul > li:nth-child(9),
	.allErrorListTable > ul > li:nth-child(10),
	.allErrorListTable > ul > li:nth-child(11),
	.orgUnitsList > ul > li:nth-child(2),
	.orgUnitsList > ul > li:nth-child(4),
	.orgUnitsList > ul > li:nth-child(5), 
	.orgUnitsList > ul > li:nth-child(6),   
	.orgUnitsList > ul > li:nth-child(8),  
	.orgUnitsList > ul > li:nth-child(9),
	.orgTable > ul > li:nth-child(2),
	.orgTable > ul > li:nth-child(4),
	.orgTable > ul > li:nth-child(6),
	.registerUnitLtable > ul > li:nth-child(1),
	.registerUnitLtable > ul > li:nth-child(4),
	.readyRecipeTable > ul > li:nth-child(1), 
	.readyRecipeTable > ul > li:nth-child(3), 
	.readyRecipeTable > ul > li:nth-child(5), 
	.readyRecipeTable > ul > li:nth-child(6),
	.readyRecipeTable > ul > li:nth-child(7),
	.readyRecipeTable > ul > li:nth-child(8),
	.SWavlUpdtsExp > ul > li:nth-child(1),
	.statusTable > ul > li:nth-child(12),
	.statusTable > ul > li:nth-child(2),
	.statusTable > ul > li:nth-child(4),
	.statusTable > ul > li:nth-child(5),
	.statusTable > ul > li:nth-child(6), 
	.statusTable > ul > li:nth-child(8),
	.statusTable > ul > li:nth-child(9), 
	.statusTable > ul > li:nth-child(10),
	.menuLbryLTable > ul > li:nth-child(4),
	.avlUpdatesExpTable > ul > li:nth-child(3),
	.availableUpdatesTable > ul > li:nth-child(6),
	.selectRecipeTable > ul > li:nth-child(1),
	.selectRecipeTable > ul > li:nth-child(4),
	.selectRecipeTable > ul > li:nth-child(5),
	.selectRecipeTable > ul > li:nth-child(6),
	.selectRecipeTable > ul > li:nth-child(7),
	.selectRecipeTable > ul > li:nth-child(8),
	.menuLibraryTable > ul > li:nth-child(1),
	.menuLibraryTable > ul > li:nth-child(2),
	.menuLbryMcfTable > ul > li:nth-child(1),
	.menuLbryMcfTable > ul > li:nth-child(2),
	.recipeCreationTable > ul > li:nth-child(1),
	.disposeInformationTable > ul > li:nth-child(1),
	.disposeInformationTable > ul > li:nth-child(3),
	.disposeInformationTable > ul > li:nth-child(5),
	.disposeInformationTable > ul > li:nth-child(6),
	.filtrationDataTable > ul > li:nth-child(1),
	.filtrationDataExpTable > ul > li:nth-child(1),
	.filtrationDataTable > ul > li:nth-child(3),
	.filtrationDataExpTable > ul > li:nth-child(3),
	.filtrationDataTable > ul > li:nth-child(4),
	.filtrationDataExpTable > ul > li:nth-child(4),
	.productMixTable > ul > li:nth-child(1),
	.productMixExpTable > ul > li:nth-child(1),
	.productMixTable > ul > li:nth-child(3),
	.productMixExpTable > ul > li:nth-child(3),
	.drinksSoldTable.drinksSoldTableDay > ul > li:nth-child(1),
	.drinksSoldTable.drinksSoldTableDay > ul > li:nth-child(3),
	.drinksSoldTable.drinksSoldTableWeek > ul > li:nth-child(1),
	.drinksSoldTable.drinksSoldTableWeek > ul > li:nth-child(3),
	.drinksSoldTable.drinksSoldTableWeek > ul > li:nth-child(4),
	.drinksSoldTable.drinksSoldTableWeek > ul > li:nth-child(5),
	.drinksSoldTable.drinksSoldTableWeek > ul > li:nth-child(6),
	.drinksSoldTable.drinksSoldTableWeek > ul > li:nth-child(7),
	.drinksSoldTable.drinksSoldTableWeek > ul > li:nth-child(8),
	.drinksSoldTable.drinksSoldTableWeek > ul > li:nth-child(9),
	.drinksSoldTable.drinksSoldTableYear > ul > li:nth-child(1),
	.drinksSoldTable.drinksSoldTableYear > ul > li:nth-child(3),
	.drinksSoldTable.drinksSoldTableYear > ul > li:nth-child(4),
	.drinksSoldTable.drinksSoldTableYear > ul > li:nth-child(5),
	.drinksSoldTable.drinksSoldTableYear > ul > li:nth-child(6),
	.drinksSoldTable.drinksSoldTableQuarter > ul > li:nth-child(1),
	.drinksSoldTable.drinksSoldTableQuarter > ul > li:nth-child(3),
	.drinksSoldTable.drinksSoldTableQuarter > ul > li:nth-child(4),
	.drinksSoldTable.drinksSoldTableQuarter > ul > li:nth-child(5),
	.drinksSoldTable.drinksSoldTableMonth > ul > li:nth-child(1),
	.drinksSoldTable.drinksSoldTableMonth > ul > li:nth-child(3),
	.drinksSoldTable.drinksSoldTableMonth > ul > li:nth-child(4),
	.drinksSoldTable.drinksSoldTableMonth > ul > li:nth-child(5),
	.drinksSoldTable.drinksSoldTableMonth > ul > li:nth-child(6),
	.drinksSoldTable.drinksSoldTableMonth > ul > li:nth-child(7),
	.drinksSoldTable.drinksSoldTableMonth > ul > li:nth-child(8),
	.holdingDataExpTable > ul > li:nth-child(4),
	.holdingDataTable > ul > li:nth-child(4),
	.holdingDataExpTable > ul > li:nth-child(7),
	.holdingDataTable > ul > li:nth-child(7),
	.utilizationExpTable > ul > li:nth-child(2),
	.utilizationExpTable > ul > li:nth-child(3),
	.utilizationExpTable > ul > li:nth-child(5),
	.utilizationTable > ul > li:nth-child(2),
	.utilizationTable > ul > li:nth-child(3),
	.utilizationTable > ul > li:nth-child(5),
	.errorListTable > ul > li:nth-child(2),
	.errorListTable > ul > li:nth-child(4),
	.errorListTable > ul > li:nth-child(6),
	.errorListTable > ul > li:nth-child(7),
	.fErrorListTable > ul > li:nth-child(2),
	.fErrorListTable > ul > li:nth-child(4),
	.fErrorListTable > ul > li:nth-child(5),
	.fErrorListTable > ul > li:nth-child(7),
	.fErrorListTable > ul > li:nth-child(8),
	.errorListTable.historicErrorsTable > ul > li:nth-child(7),
	.errorListTable.historicErrorsTable > ul > li:nth-child(8),
	.errorListTable.historicErrorsTable > ul > li:nth-child(9),
	.fErrorListTable.historicErrorsTable > ul > li:nth-child(7),
	.fErrorListTable.historicErrorsTable > ul > li:nth-child(8),
	.fErrorListTable.historicErrorsTable > ul > li:nth-child(9),
	.fErrorListTable.historicErrorsTable > ul > li:nth-child(10),
	.cleaningDataTable > ul > li:nth-child(1),
	.cleaningDataTable > ul > li:nth-child(3),
	.cleaningDataTable > ul > li:nth-child(5),
	.cookingDataTable > ul > li:nth-child(1),
	.cookingDataTable > ul > li:nth-child(4),
	.topSoldDrinksTable > ul > li:nth-child(1),
	.topSoldDrinksTable > div > div:nth-child(1),
	.gridView.selectedUnitBg div.colm2D:nth-child(3),
	.mediaPreparationTable > ul > li:nth-child(1),
	.mediaPreparationTable > ul > li:nth-child(4),
	.mediaPreparationTable > ul > li:nth-child(5),
	.mediaPreparationTable > ul > li:nth-child(6),
	.mediaPreparationTable > ul > li:nth-child(7),
	.mediaPreparationTable > ul > li:nth-child(8),
	.mediaScreensaverTable > ul > li:nth-child(1),
	.mediaScreensaverTable > ul > li:nth-child(4),
	.mediaScreensaverTable > ul > li:nth-child(5),
	.mediaScreensaverTable > ul > li:nth-child(6),
	.mediaScreensaverTable > ul > li:nth-child(7),
	.mediaScreensaverTable > ul > li:nth-child(8),
	.mediaScreensaverTable > ul > li:nth-child(9),
	.mediaScreensaverTable > ul > li:nth-child(10),
	.mediaCompilationTable > ul > li:nth-child(1),
	.mediaCompilationTable > ul > li:nth-child(4),
	.mediaCompilationTable > ul > li:nth-child(5),
	.mediaCompilationTable > ul > li:nth-child(6),
	.mediaCompilationTable > ul > li:nth-child(7),
	.mediaCompilationTable > ul > li:nth-child(8),
	.menuUpdateStatusExpTable > ul.tBody > li:nth-child(1),
	.menuUpdateStatusExpTable > ul.tBody > li:nth-child(3),
	.panel-body.uploadHistoryExpTable > ul > li:nth-child(1),
	.panel-body.uploadHistoryExpTable > ul > li:nth-child(2),
	.panel-body.uploadHistoryExpTable > ul > li:nth-child(4),
	.panel-body.uploadHistoryExpTable > ul > li:nth-child(6),
	.mediaAllTable > ul > li:nth-child(1),
	.mediaAllTable > ul > li:nth-child(5),
	.mediaAllTable > ul > li:nth-child(6),
	.mediaAllTable > ul > li:nth-child(7),
	.panel-body.updateStatusExpTable > ul > li:nth-child(1),
	.haccpUpdateStatusTable > ul > li:nth-child(1),
	.haccpUpdateStatusTable > ul > li:nth-child(6),
	.haccpUpdateStatusTable > ul > li:nth-child(7),
	.updateStatusTable > ul > li:nth-child(1),
	.updateStatusTable > ul > li:nth-child(5),
	.updateStatusTable > ul > li:nth-child(6),
	.updateStatusTable > ul > li:nth-child(7),
	.updateStatusTable > ul > li:nth-child(9),
	.mediaStatus > ul > li:nth-child(1),
	.mediaStatus > ul > li:nth-child(3),
	.mediaStatus > ul > li:nth-child(5), 
	.mediaStatus > ul > li:nth-child(7), 
	.menuAStatusTable > ul > li:nth-child(2),
	.menuAStatusTable > ul > li:nth-child(3),
	.menuAStatusTable > ul > li:nth-child(8),
	.menuAStatusTable > ul > li:nth-child(9),
	.menuAStatusTable > ul > li:nth-child(11),
	.unitRegListTable > ul > li:nth-child(1),
	.unitRegListTable > ul > li:nth-child(3),
	.unitRegListTable > ul > li:nth-child(4),
	.unitRegListTable > ul > li:nth-child(5),
	.unitRegListTable > ul > li:nth-child(7),
	.unitRegListTable > ul > li:nth-child(8),
	.unitRegListTable > ul > li:nth-child(9),
	.unitRegCList > ul > li:nth-child(1),
	.unitRegCList > ul > li:nth-child(3),
	.unitRegCList > ul > li:nth-child(4),
	.unitRegCList > ul > li:nth-child(5),
	.unitRegCList > ul > li:nth-child(7),
	.unitRegCList > ul > li:nth-child(8),
	.STComfmtList > ul > li:nth-child(1),
	.STComfmtList > ul > li:nth-child(3), 
	.STComfmtList > ul > li:nth-child(5), 
	.STComfmtList > ul > li:nth-child(6), 
	.STComfmtList > ul > li:nth-child(7), 
	.ntnLibraryTable > ul > li:nth-child(1),
	.ntnLibraryTable > ul > li:nth-child(3),
	.ntnLibraryTable > ul > li:nth-child(4),
	.ntnLibraryTable > ul > li:nth-child(5),
	.ntnLibraryTable > ul > li:nth-child(6),
	.ntnLibraryTable > ul > li:nth-child(7),
	.listUnitsTable > ul > li:nth-child(1),
	.listUnitsTable > ul > li:nth-child(4),
	.listUnitsTable > ul > li:nth-child(5),
	.listUnitsTable > ul > li:nth-child(7),
	.listUnitsTable > ul > li:nth-child(8),
	.listUnitsTable > ul > li:nth-child(9),
	.SWlistUnitsTable > ul > li:nth-child(1),
	.SWlistUnitsTable > ul > li:nth-child(4),
	.SWlistUnitsTable > ul > li:nth-child(5),
	.SWlistUnitsTable > ul > li:nth-child(7), 
	.SWlistUnitsTable > ul > li:nth-child(8), 
	.SWlistUnitsTable > ul > li:nth-child(9),  
	.SWlistUnitsTable > ul > li:nth-child(11), 
	.reportAsgnList > ul > li:nth-child(1),
	.reportAsgnList > ul > li:nth-child(4),
	.reportAsgnList > ul > li:nth-child(5),
	.reportAsgnList > ul > li:nth-child(7),
	.reportAsgnList > ul > li:nth-child(8),
	.reportAsgnList > ul > li:nth-child(9), 
	.listLocationsTable > ul > li:nth-child(1),
	.listLocationsTable > ul > li:nth-child(4),
	.listLocationsTable > ul > li:nth-child(5),
	.listLocationsTable > ul > li:nth-child(6),
	.newReportNS.forDesktopR,
	.gridViewAMcolm6DSM .gridView .gridTableView > ul > li:nth-child(1),
	.detailsViewOuter > ul.tHead { 
		display:none;
	}
	.createdEditedDetailsO .SMDevice,
	.bakingPlansList > ul.tBody > li .bakingPlansSM,
	.bakingPlansList > ul.tBody > li .bakingPlansSMD,
	.SWuStatusTable > ul.tBody > li .SWuStatusSM,
	.SWuStatusTable > ul.tBody > li .SWuStatusAN,
	.SWlistUnitsTable > ul.tBody > li .unitsListAN,
	.SWlistUnitsTable > ul.tBody > li .unitsListAN,
	.reportAsgnList > ul.tBody > li .unitsListAN,
	.reportAsgnList > ul.tBody > li .unitsListAN,
	.unitRegListTable > ul.tBody > li .unitsListAN, 
	.unitRegCList > ul.tBody > li .unitsListAN,  
	.STComfmtList > ul.tBody > li .unitsListSM,  
	.STComfmtList > ul.tBody > li .unitsSM, 
	.listUnitsTable > ul > li .unitsListAN,
	.gridView .gridTableView.gridTableViewSW > ul > li:nth-child(1),
	.statusTable > ul.tBody > li .statusLAN,
	.orgUnitsList > ul.tBody > li .unitsListAN,
	.orgUnitsList > ul.tBody > li .unitsListSM, 
	.orgUnitsList > ul.tBody > li .unitsListSML,
	.orgTable > ul.tBody > li .orgListSM,
	.readyRecipeTable > ul.tBody > li .menuSListSM,  
	.readyRecipeTable > ul.tBody > li .menuSListSMF, 
	.readyRecipeTable > ul.tBody > li .menuSListRS,
	.newReportNS.forMObileR {
		display:block;
	}
	.uploadForm{
		width:100%;
	}
	.uploadForm > ul > li .dragAndBrowse .loading { 
		right:120px; 
	} 
	.readyRecipeTable > ul > li:nth-child(9){
		width:150px;
	}
	.readyRecipeTable > ul.tBody > li:nth-child(9) {
		width: 138px;
	}
	.readyRecipeTable > ul > li:nth-child(2) {
		width: 60px;
		padding-right: 0px; 
	}
	.assignOrgHeader > ul > li:nth-child(2){
		padding-left:20px;
	}
	.cbPhotogallery > ul > li{
		width:33.333%;
	}
	.rcScreensaverList{
		width:96%;
		margin:0px 2%;
	}
	.rcSettingsTable > ul > li:nth-child(3) .form-control, 
	.rcSettingsTable > ul > li:nth-child(4) .form-control {
		width: 50px; 
	}
	.registerUnitLtable > ul > li { 
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
	}
	.SWuStatusTable > ul > li:nth-child(6) {
		width: 140px;
	} 
	.registerUnitLtable > ul.tHead > li:nth-child(3) {
		width:150px;
		text-align: left;
	}
	.registerUnitLtable > ul.tBody > li:nth-child(3) {
		width:138px;
	}
	.reportDetailsModal .reportDetailsD p > .pFromDate,   
	.reportDetailsModal .reportDetailsD p > .pToDate{
		float:left;
		width:100%;
		margin-bottom:5px;
	} 
	.activateNowModal .woActivationBtn{
		white-space: break-spaces;
		line-height: 20px;
		padding: 12px 0px;
	} 
	.SWavlUpdtsDiv > ul > li:nth-child(1){
		width:40px;
	} 
	.orgTable > ul.tHead > li:nth-child(7){
		text-indent:-999px;
	}
	.orgTable > ul > li:nth-child(3){
		width:auto;
	}
	.orgTable > ul > li:nth-child(5) {
		width: 110px;
	}
	.orgTable > ul > li:nth-child(7){
		width:55px; 
	}
	.orgUnitsList > ul > li:nth-child(3),
	.orgTable > ul > li:nth-child(3){
		padding-left:0px;
	}
	.allErrorListTable > ul > li:nth-child(6),
	.allErrorListTable.allHErrorListTable > ul > li:nth-child(6){
		width:auto;
	}
	.reportHeader > ul > li:nth-child(1) > a,
	.softwareUpdateHeader > ul > li a,
	.tableHNav > ul > li a,
	.tableHNav > ul > li:nth-child(1) a,
	.menuUpdateHeader > ul > li a{
		float:left;
		width:50%;
		text-align:center;
		margin:0px;
		padding:10px 0px;
	}  
	.reportHeader > ul > li:nth-child(1) > a.OrgChartSTNav,
	.reportHeader > ul > li:nth-child(1) > a.UnitSTNav { 
		padding: 10px 5px 10px 30px;  
		float:inherit; 
	}
	.tableHNav > ul > li:nth-child(1) a:nth-child(3),
	.menuUpdateHeader > ul > li a:last-child{ 
		width:100%; 
		border-top: 1px solid #7e8690;
	} 
	.tableHNav > ul > li:nth-child(2){ 
		width:100%; 
		border-top: 1px solid #7e8690;
		text-align:center;
		padding:10px 0px 10px;
	}   
	.navHeader > ul > li:nth-child(1),
	.gridViewSMcolm39DSM .gridView .colm9D,
	.gridViewSMcolm39DSM .gridView .colm3D,
	.gridViewcolm6DSM .gridView .colm6D,
	.gridViewcolm4DSM .gridView .colm4D,
	.gridViewAMcolm3D6DSM .gridView .colm6D,
	.gridViewAMcolm3D6DSM .gridView .colm3D,
	.gridViewAMcolm6DSM .gridView .colm6D,
	.gridViewAMcolm6DSM .gridView .colm4D,
	.frypotStsLegendSection,
	.subscriptionForm,
	.eaasWrapper,
	.deviceRegisterWrapper,
	.billingOForm > ul > li:nth-child(1),
	.billingOForm > ul > li:nth-child(2),
	.billingFormWrapper > ul > li:nth-child(1),
	.billingFormWrapper > ul > li,
	.billingForm > ul > li.colm50,
	.billingForm > ul > li.colm33,
	.billingForm > ul > li, 
	.billingPlanForm > ul > li.colm50,
	.billingPlanForm > ul > li.colm33,
	.billingPlanForm > ul > li,
	.cookieWrapperBtn > ul > li,
	.newReportFormT > ul > li:nth-child(1),   
	.newReportFormT > ul > li:nth-child(2),
	.datePeriodDiv > ul > li:nth-child(1),
	.datePeriodDiv > ul > li:nth-child(2),
	.reportOptionsList > ul > li, 
	.orgznMgmtHeader > ul > li:nth-child(1), 
	.orgHeader > ul > li:nth-child(1),
	.reportHeader > ul > li:nth-child(1),
	.SWavlUpdtsExp > ul > li:nth-child(2),
	.SWavlUpdtsExp > ul > li:nth-child(3),
	.statusExpTable > ul > li:nth-child(1), 
	.statusExpTable > ul > li:nth-child(2), 
	.statusExpTable > ul > li:nth-child(3),
	.tableHNav > ul > li,
	.softwareUpdateHeader > ul > li{
		float:left;
		width:100%;
	} 
	.SWavlUpdtsExp > ul > li:nth-child(2),
	.SWavlUpdtsExp > ul > li:nth-child(3){
		padding-left:40px;
	} 
	.navHeader > ul > li:nth-child(2),
	.orgznMgmtHeader > ul > li:nth-child(2),
	.orgHeader > ul > li:nth-child(2),
	.reportHeader > ul > li:nth-child(2),
	.softwareUpdateHeader > ul > li:nth-child(2){
		float:left;
		width:100%;
		max-width:100%;
		text-align:center;
		padding:10px 0px 10px;
		border-top: 1px solid #7e8690;
	}
	.reportHeader > ul > li .checkBoxListGrp {
		float:left;
		width:100%;
		text-align:center;
		padding:0px 0px 10px;
		border-bottom: 1px solid #7e8690;
		margin-bottom:10px;
	}
	.brandsLibraryTable > ul > li:nth-child(2) > img {
		max-height: 22px; 
		max-width:240px;
	}
	.navbarRight{
		border-left:0px;
	}
	.newReportForm .endDatePicker,
	.newReportForm .input-group.timeSelection,
	.newReportForm .input-group.startDateSelection{
		width:180px;
	}
	.cookieWrapperBtn > ul > li:nth-child(2){
		width:100%;
		text-align:center;
		padding:0px;
	}
	.cookieWrapper{
		padding: 10px 5px 3px;
		bottom: 60px;
	}
	.LoginBtnWrapper{
		bottom: 95px;
	}
	.supportButtonWrapper{
		margin:5px 0px;
	}
	.mediaPreviewModal .modal-md,
	.subscriptionModal .modal-md,
	.confirmationRMModal .modal-md,
	.overwriteMediaModal .modal-md {
		min-width:98%;
	}
	.modal-dialog.modal-md,
	.modal-dialog.modal-md.sgModal,
	.modal-dialog.swaggerInfoModal,
	.mediaPreviewModal .modal-md,
	.selectRecipeModal .modal-dialog-centered.modal-md, 
	.selectRecipeModal .modal-dialog.modal-md, 
	.activateNowModal .modal-dialog.modal-md { 
		width:98%;
		min-width:98%;
	}
	.billingPlanForm > ul > li:nth-child(2),
	.billingPlanForm > ul > li:nth-child(3),
	.billingForm > ul > li:nth-child(2),
	.billingForm > ul > li:nth-child(3) {
		padding-left: 0px;
	}
	.btn.billingFormRegister{
		float:inherit;
		margin:15px 15%;
		width:70%;
	} 
	.subscriptionList > ul > li:nth-child(2),
	.subscriptionList > ul > li:nth-child(4){
		width:150px;
	}
	.subscriptionList > ul > li:nth-child(1){
		font-size: 14px;
	} 
	.subscriptionPlanPro .freeTrialIcon{   
		left:-65px;
		top:-11px;
		width:80px;
		height:80px;
		background: url("../images/icons/freetrial.svg") no-repeat center center;
		background-size: 80px auto;
		z-index:1;
	}
	.subscriptionPlanPro .btn.viewPrice { 
		font-size: 13px; 
		padding: 6px 10px;
	} 
	.subscriptionList > ul > li .imageSpan {
		width: 20px;
		height: 20px; 
	} 
	.subscriptionForm .mCSB_inside > .mCSB_container {
		margin-right: 15px;
	}
	.subscriptionList.subscriptionH, 
	.subscriptionList.subscriptionFooter {
		padding-right: 15px;
	} 
	.subscriptionList > ul > li .termsAndC{
		float:none;
		line-height:inherit;
		margin:10px;
		font-size: 12px;
		padding-left: 5px;
	}
	.subscriptionList > ul > li .btn.chooseProBtn,
	.subscriptionList > ul > li .btn.chooseBasicBtn { 
		padding:8px 5px; 
		min-width: 110px; 
	}
	.thankYouEAAS {
		width: auto;
		float: left;
		margin: 80px 15px 0px;
		background: url("../images/whiteTransparent.png") repeat;
	}
	.signinupDiv .signInDiv, .signinupDiv .signUpDiv,
	.existingLoginForm {
		background: url("../images/whiteTransparent.png") repeat;
		border:0px;
	}
	.unitRegFormWrapper { 
		padding: 0px 15px;
	}
	.unitRegSuccess {
		width:100%;
		float:left;
		padding-right: 0px;
	}
	.registerYourDevice > ul > li > span {
		float: none; 
		min-width: auto;
		max-width: inherit;
	}
	.unitRegSuccess .unitRegThanksCnt > img,
	.thankYouEAAS .thankYouEAASCnt > img { 
		max-width: 80px;
	} 
	.textWRadioBtnList,
	.cbAddMenus{
		margin:10px 2%;
		width:96%;
	}
	.reWrapperTable,
	.frymasterRCForm { 
		padding: 0px 5px;
	}
	.frymasterRCForm > ul > li .scheduledDaysList > .customCheckbox {
		width: 110px; 
	}
	.cbMenuCategory > ul > li {
		width: 33%; 
	}
	.frymasterRCForm > ul > li .monthList > .btn {
		width: 46%; 
	}
	.frymasterRCForm ul > li .customRadioBtn,
	.frymasterRCForm ul > li.sundayList .customRadioBtn { 
		width: 48%;
	}
	.frymasterRCForm > ul > li.smallFormFields > input {
		display: block;
		margin-top: 5px;
		clear: both;
		float: right;
		width: 100%;
	}
	.rcDeviceWrapper {
		float: left;
		width: 100%;
		clear: both;
		margin: 95px 0px 0px;
	}
	.modalSearchForm {
		width: 100%;
	}
	.testOnUnitTable,
	.recipeEditorWrapper .recipeEditorF,
	.recipeEditorWrapper .recipeEditorH,
	.recipeEditorWrapper {
		border-radius: 0px;
	}
	.rcDeviceWrapper .footerText {
		padding: 5px 10px;
		text-align: center;
	}
	.cbcategory > ul > li {
		width: 50%;
	}
	.recipeCBGrid > ul > li:nth-child(2) { 
		padding-right: 0px;
		padding-left: 0px;
	}
	.recipeCBGrid > ul > li:nth-child(1) {
		width: 150px;
		padding-right:0px; 
	}
	.rcSettingsLi01 > ul > li { 
		padding: 0px 0px; 
	}
	.cbcategory > ul > li > .cbcategoryBox {
		width: 70px;
	}
	.cbcategory > ul > li > .cbcategoryBox > .navIcon {
		width: 70px;
		height: 70px;
		line-height: 70px;
	}
	.cbcategory > ul > li > .cbcategoryBox > .navIcon > img {
		max-height: 70px;
		max-width: 70px;
	}
	.cbAddRecipe > ul > li:nth-child(2) {
		font-size: 16px;
		padding-left: 0px;
	}
	.recipeCBGrid > ul > li > a > .navTitle {
		padding: 0px 0px 0px;
		font-size: 14px;
	}
	.recipeEditorGrid {
		padding: 10px 10px;
	}
	.rcSaveForm .rcNameDiv,
	.rcSaveForm .rcNameDiv .form-control,
	.userManagementWrapper .exitingUserRolesSelection .exitingRolesSelect {
		width: 100%;
	}
	.rcSaveForm .importImageDiv {
		float: none;
		margin: 0px auto;
		width: 100%;
		max-width: 220px;
		display:table;
	}
	.rcSaveForm .rcNameDiv > label {
		padding: 5px 5px 5px 0px;
	}
	.recipeCreationForm > ul > li:nth-child(1),
	.recipeCreationForm > ul > li:nth-child(4) {
		width: 40px;
	}
	.recipeCreationForm > ul > li:nth-child(1) > .btn,
	.recipeCreationForm > ul > li:nth-child(4) > .btn {
		width: 30px;
		height: 30px;
		background-size: 30px 30px;
	}
	.rcPreheatForm > ul > li,
	.recipeCreationForm > ul > li {
		padding: 5px 5px;
	}
	.rcPreheatTable > ul > li {
		padding: 0px 5px;
	}
	.recipeCreationForm > ul > li:nth-child(2) {
		width: 30px;
	}
	.cbSearchForm,
	.cbAddRecipe,
	.timerForTempList,
	.rcTempList,
	.rcSettingsLi01,
	.rcSettingsList,
	.rcSettingsForm {
		width: 100%;
		margin: 0px;
	}
	.chartHeaderTitleTable > ul > li {
		padding: 7px 3px;
	}
	.chartHeaderTitleTable > ul > li:nth-child(1) {
		width: 70px;
	}
	.chartHeaderTitleTable > ul > li:nth-child(3) {
		width: 110px;
	}
	.chartHeaderTitleTable > ul > li .chartTitleLgnd {
		padding: 0px 0px 0px;
	}
	.frypotLegendSection {
		margin: 5px 0px 10px 10px;
	}
	.frypotLegendSection > li {
		margin: 5px 15px 5px 0;
	}
	.modal-dialog.modal-lg.frypotInfoModal {
		width: 95%;
	}
	.frypotInfoModal.modal:before {
		height: auto;
		min-height: max-content;
	}
	.filtrationDataTable > ul > li:nth-child(6),
	.filtrationDataExpTable > ul > li:nth-child(6),
	.productMixTable > ul > li:nth-child(5),
	.productMixExpTable > ul > li:nth-child(5) {
		width: 90px;
		padding-left: 0px;
		padding-right: 0px;
	}
	.filtrationDataTable > ul > li:nth-child(5),
	.filtrationDataExpTable > ul > li:nth-child(5),
	.productMixTable > ul > li:nth-child(4),
	.productMixExpTable > ul > li:nth-child(4) {
		width: 110px;
		padding-left: 0px;
		padding-right: 0px;
	}
	.productMixExpTable > ul > li:nth-child(2) {
		text-indent: 0px;
	}
	.mediaHeader > ul > li {
		display: block;
		float: left;
		font-size: 14px;
		padding: 8px 5px 9px;
	}
	.mediaHeader.mediamclibraryH > ul > li:nth-child(3) {
		width: 33.33%;
	}
	.mediaHeader > ul > li:nth-child(1),
	.mediaHeader > ul > li:nth-child(2),
	.mediaHeader > ul > li:nth-child(3),
	.mediaHeader > ul > li:nth-child(4) {
		width: 25%;
		text-align: center;
	}
	.mediaHeader > ul > li:nth-child(5) {
		width: 100%;
		text-align: right;
		float: left;
		border-top: 1px solid #7e8690;
	} 
	.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedText h5 {
		font-size: 12px;
		width: 120px;
	}
	.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedText h6 {
		font-size: 12px;
	}
	.mediaUploadFTable > ul > li:nth-child(2) > button {
		padding: 24px 0px;
		font-size: 14px;
	}
	.reportHeader > ul > li a {
		padding: 8px 10px 9px;
	}
	.utilizationTable > ul > li:nth-child(6),
	.utilizationTable > ul > li:nth-child(4),
	.utilizationExpTable > ul > li:nth-child(6),
	.utilizationExpTable > ul > li:nth-child(4) {
		width: 80px;
	} 
	.cookingDataTable > ul > li:nth-child(5) {
		width: auto;
		min-width: 120px;
		text-align: center;
	}
	.cookingDataTable > ul > li:nth-child(3) {
		padding: 10px 0px;
		width: auto;
		min-width: 100px;
	}
	.gridView .gridTableView.gridTableViewSW > ul > li {
		padding-left: 0px;
	}
	.gridView .gridTableView.gridTableViewSW > ul > li > ul > li > label {
		padding-left: 0px;
		width: 150px;
	}
	.gridViewAMcolm6DSM .gridView .gridTableView > ul > li:nth-child(2) {
		padding: 0px;
	}
	.gridViewAMcolm6DSM .gridView .gridTableView > ul > li:nth-child(2) > ul > li > label {
		padding-left: 0px;
	}
	.piechartvalue {
		margin-top: 0px;
	}
	.topSoldDrinkschartouterdiv {
		width: 100%;
		float: left;
		clear: both;
		height: 380px;
	}
	#topSoldDrinkschartdiv,
	#convothermtop10Productschartdiv,
	#top10Productschartdiv {
		width: 100%;
		float: left;
		clear: both;
		height: 300px;
	}
	.topSoldDrinks {
		width: 100%;
		float: left;
		clear: both;
	}
	.navbarLeft.fivePillarsHeader > li .navStoreText > b {
		display: inline;
	}
	.navbarLeft.fivePillarsHeader > li {
		width: auto;
		word-break: break-word;
		padding: 3px 0px 2px 10px;
	}
	.navbarLeft.fivePillarsHeader > li:first-child {
		border-right: 0px;
	}
	.navbarLeft > li .navStoreIcon { 
		width: 22px;
		line-height: 22px; 
	}
	.navbarLeft > li .navStoreIcon > img {
		max-width: 22px;
		max-height: 22px;
	}
	.gridView.selectedUnitBg .colm2D {
		width: 20%;
	}
	.gridView.selectedUnitBg .colm8D {
		width: 80%;
	}
	.gridView .colmDi > .titleH2Div {
		font-size: 16px;
		padding: 0px 0px 0px;
	}
	.gridView .colmDi > .titleH4Div {
		padding: 0px 0px 5px;
	}
	.navbar .welbiltLogo {
		margin: 0px auto;
	}
	.fErrorListTable > ul.tBody > li .errorTypeSM,
	.errorListTable > ul.tBody > li .errorTypeSM {
		display: inline-block;
	}
	.gridView.selectedUnitBg .colm2D.colm2D1 .colmDi {
		text-align: center;
	}
	.gridView .colmDi > .divStatusWithIcon.divStatusWithIconSM {
		width: 80px;
		float: none;
		clear: both;
		margin: 0px auto;
		display: inline-block;
		font-size: 14px;
	}
	.selectRecipeTable > ul > li .unitsRCSM,
	.selectRecipeTable > ul > li .unitsRCM,
	.selectRecipeTable > ul > li .unitsRCMD,
	.disposeInformationTable > ul.tBody > li .disposeInformationSM,
	.fErrorListTable > ul.tBody > li .errorListSM,
	.errorListTable > ul.tBody > li .errorListSM,
	.cleaningDataTable > ul.tBody > li .cleaningTypeSM,
	.mediaPreparationTable > ul.tBody > li .mediaSListSM,
	.mediaPreparationTable > ul.tBody > li .mediaSListRS,
	.mediaPreparationTable > ul.tBody > li .mediaSListAS,
	.mediaScreensaverTable > ul.tBody > li .mediaSListSM,
	.mediaScreensaverTable > ul.tBody > li .mediaSListRS,
	.mediaScreensaverTable > ul.tBody > li .mediaSListAS,
	.mediaCompilationTable > ul.tBody > li .mediaSListSM,
	.mediaCompilationTable > ul.tBody > li .mediaSListRS,
	.mediaCompilationTable > ul.tBody > li .mediaSListAS,
	.haccpUpdateStatusTable > ul.tBody > li .updateStatusSM,
	.haccpUpdateStatusTable > ul.tBody > li .updateStatusSB,
	.updateStatusTable > ul.tBody > li .updateStatusSM,
	.updateStatusTable > ul.tBody > li .updateStatusSB,
	.mediaStatus > ul.tBody > li .updateStatusSM,
	.mediaStatus > ul.tBody > li .updateStatusSB,
	.menuAStatusTable > ul.tBody > li .menuAStatusSM,
	.menuAStatusTable > ul.tBody > li .menuAStatusSB,
	.unitRegListTable > ul.tBody > li .unitsRSM,
	.unitRegListTable > ul.tBody > li .unitsASM,
	.unitRegListTable > ul.tBody > li .unitsListSM,
	.unitRegCList > ul.tBody > li .unitsRSM, 
	.unitRegCList > ul.tBody > li .unitsListSM,
	.STComfmtList > ul.tBody > li .STstatusSM, 
	.STComfmtList > ul.tBody > li .unitsListSM, 
	.STComfmtList > ul.tBody > li .unitsSM,
	.ntnLibraryTable > ul.tBody > li .unitsListSM,
	.ntnLibraryTable > ul.tBody > li .unitsListSML,
	.listUnitsTable > ul > li .unitsListSM,
	.listUnitsTable > ul > li .unitsListSML,
	.SWlistUnitsTable > ul.tBody > li .unitsListSM,
	.SWlistUnitsTable > ul.tBody > li .unitsListSML,
	.reportAsgnList > ul.tBody > li .unitsListSM,
	.reportAsgnList > ul.tBody > li .unitsListSML, 
	.listLocationsTable > ul.tBody > li .locationsListSM {
		display: block;
		font-size: 12px;
	} 
	.selectRecipeTable > ul > li:nth-child(2),
	.menuUpdateStatusExpTable > ul > li:nth-child(2),
	.haccpUpdateStatusTable > ul > li:nth-child(2),
	.updateStatusTable > ul > li:nth-child(2),
	.mediaStatus > ul > li:nth-child(2),
	.unitRegListTable > ul > li:nth-child(2),
	.unitRegCList > ul > li:nth-child(2),
	.STComfmtList > ul > li:nth-child(2),
	.ntnLibraryTable > ul > li:nth-child(2) {
		padding-left: 10px;
	}
	.haccpUpdateStatusTable > ul > li:nth-child(5),
	.updateStatusTable > ul > li:nth-child(5),
	.mediaStatus > ul > li:nth-child(5),
	.menuAStatusTable > ul > li:nth-child(7) {
		width: 130px;
		padding: 10px 0px;
		text-align: left;
	}
	.recipeCreationTable > ul > li:nth-child(2),
	.mediaAllTable > ul > li:nth-child(3) {
		width: auto;
		padding-left: 10px;
	}
	.recipeCreationTable > ul > li:nth-child(3) {
		width: 40px;
		text-indent: -99999px;
	}
	.recipeCreationTable > ul.tBody > li:nth-child(3) {
		background: url(../images/icons/RClink.png) no-repeat center center;
		background-size: 30px 30px;
	}
	.mediaAllTable > ul > li:nth-child(2) > img {
		height: 20px;
	}
	.menuAssignFTable .dragedDetailsTable > span {
		color: #a7b8c8;
		font-size: 14px;
		max-width: 280px;
	}
	.menuAssignFTable > ul > li:nth-child(2) {
		width: 70px;
	}
	.menuAssignFTable .dragedDetailsTable > .menuManualCount,
	.menuAssignFTable .dragedDetailsTable > .menuRecipeCount,
	.menuAssignFTable .dragedDetailsTable > .menuSettingsCount {
		width: 100%;
		text-align: center;
		padding: 0px;
		float: left;
	}
	.menuAssignFTable .dragedDetailsTable .menuManualText,
	.menuAssignFTable .dragedDetailsTable .menuRecipeText,
	.menuAssignFTable .dragedDetailsTable .menuSettingsText {
		padding: 0px 0px 0px 25px;
	}
	.menuAssignFTable > ul > li:nth-child(2) > button {
		padding: 15px 5px;
		font-size: 14px;
	} 
	.mediaAllTable > ul.tBody > li:nth-child(3),
	.mediaAllTable > ul.tBody > li:nth-child(4),
	.mediaAllTable > ul.tBody > li:nth-child(5),
	.mediaAllTable > ul.tBody > li:nth-child(6) {
		font-size: 16px;
	}
	.SWlistUnitsTable > ul > li:nth-child(3),
	.reportAsgnList > ul > li:nth-child(3),
	.unitRegListTable > ul > li:nth-child(3),
	.unitRegCList > ul > li:nth-child(3),
	.ntnLibraryTable > ul > li:nth-child(3),
	.listUnitsTable > ul > li:nth-child(3),
	.listLocationsTable > ul > li:nth-child(3) {
		padding-left: 0px;
	}
	.listLocationsTable > ul > li:nth-child(2){
		width:50px;
	}
	.listLocationsTable > ul.tHead > li:nth-child(8){
		width:60px;
	}
	.listLocationsTable > ul > li:nth-child(8){
		width:48px;
	}
	.listLocationsTable > ul > li:nth-child(1), 
	.listLocationsTable > ul > li:nth-child(3),
	.listLocationsTable > ul > li:nth-child(4),
	.listLocationsTable > ul > li:nth-child(5),
	.listLocationsTable > ul > li:nth-child(7), 
	.drinksSoldTable.drinksSoldTableDay > ul > li:nth-child(2),
	.drinksSoldTable.drinksSoldTableWeek > ul > li:nth-child(2),
	.drinksSoldTable.drinksSoldTableYear > ul > li:nth-child(2),
	.drinksSoldTable.drinksSoldTableQuarter > ul > li:nth-child(2),
	.drinksSoldTable.drinksSoldTableMonth > ul > li:nth-child(2) {
		width: auto;
	}
	.disposeInformationTable > ul > li:nth-child(4) {
		width: 60px;
		padding-left: 0px;
		padding-right: 0px;
	}
	.updateStatusTable > ul > li:nth-child(5){
		font-size:14px;
	}
	.disposeInformationTable > ul > li:nth-child(2) {
		font-size: 12px;
	}
	.disposeInformationTable > ul > li:nth-child(7) {
		width: 100px;
		padding-left: 5px;
		padding-right: 5px;
	}
	.drinksSoldTable.drinksSoldTableDay > ul > li:nth-child(4),
	.drinksSoldTable.drinksSoldTableWeek > ul > li:nth-child(10),
	.drinksSoldTable.drinksSoldTableYear > ul > li:nth-child(7),
	.drinksSoldTable.drinksSoldTableQuarter > ul > li:nth-child(6),
	.drinksSoldTable.drinksSoldTableMonth > ul > li:nth-child(9) {
		width: 80px;
		padding: 10px 0px;
	}
	.drinksSoldTable.drinksSoldTableDay > ul > li:nth-child(5),
	.drinksSoldTable.drinksSoldTableWeek > ul > li:nth-child(11),
	.drinksSoldTable.drinksSoldTableYear > ul > li:nth-child(8),
	.drinksSoldTable.drinksSoldTableQuarter > ul > li:nth-child(7),
	.drinksSoldTable.drinksSoldTableMonth > ul > li:nth-child(10) {
		width: 100px;
	}
	.fErrorListTable.historicErrorsTable > ul > li:nth-child(6) p,
	.errorListTable > ul > li p,
	.fErrorListTable > ul > li:nth-child(6) p {
		max-width: 160px;
	} 
	.ntnLibraryTable > ul.tHead > li:nth-child(9) { 
		text-indent: -999px;
	}
	.contentViewModal .modal-md{
		width:100%;
		min-width:100%;
	}
	.detailsViewHeader > ul > li,
	.detailsViewOuter > ul > li{
		width:100%;
		float:left;
	}
	.detailsViewHeader > ul > li:nth-child(2) {
		text-align: left;
		padding-right: 0px;
		padding-top:0px;
	}
	.topSoldDrinksTable > ul > li:nth-child(4),
	.topSoldDrinksTable > div > div:nth-child(4),
	.listLocationsTable > ul > li:nth-child(7) {
		width: 80px;
		padding: 7px 0px;
	}
	.topSoldDrinksTable > div > div:nth-child(5),
	.topSoldDrinksTable > ul > li:nth-child(5) {
		width: 100px;
		text-align: center;
	} 
	.unitRegListTable > ul > li:nth-child(6),
	.unitRegCList > ul > li:nth-child(6){
		width:160px;
	} 
	.reportAsgnList > ul > li:nth-child(6),
	.listUnitsTable > ul > li:nth-child(6) {
		min-width: 130px;
		width: auto;
		padding: 7px 5px;
		text-align: left;
	}
	.filterListOuter .headerFilterSearch {
		width: 100%;
	}
	.filterCategoryTable > ul > li:nth-child(2) {
		padding-right: 0px;
	}
	.countryCheckbox {
		text-align: left;
	}
	.countryCheckbox > .customCheckbox {
		margin: 5px 0px 5px;
		padding: 0px 5px;
		width: 49%;
	}
	.uploadHistoryExpTable > ul > li h4,
	.uploadHistoryExpTable > ul > li h5,
	.uploadHistoryExpTable > ul > li h6 {
		max-width: 160px;
	}
	.formWrapperL,
	.formWrapperR,
	.newReportWrapperL,
	.newReportWrapperR {
		width: 100%;
		float:left;
		padding-right: 0px;
		min-height: auto;
	}
	.reportTypeList {
		padding: 10px 0px;
	}
	.formWrapperR,
	.newReportWrapperR {
		padding: 10px 0px 10px 0px;
		border-left: none;
	} 
	.newReportWrapper {
		padding: 10px 10px;
	}
	.newReportForm > h6,
	.reportTypeList > h6 {
		padding: 0px 0px 10px;
	}
	.reportTypeList > ul > li .ErrorReportIcon {
		width: 100%;
		float: none;
		clear: both;
		padding: 0px 0px 0px 0px;
	}
	.newReportForm > ul > li .input-group,
	.newReportForm > ul > li > label {
		clear: none;
	}
	.addUserForm > ul > li .form-control {
		font-size: 18px;
	}
	.addUserForm > ul > li.cityName,
	.addUserForm > ul > li.streetName {
		width: 100%;
		margin-right: 0px;
	}
	.addUserForm > ul > li.streetNumber {
		width: 50%;
	}
	.addUserForm > ul > li.zipcode {
		width: 48%;
		margin: 0px;
		float: right;
	}
	.mediaUploadFTable>ul>li:nth-child(1) {
		width: 74%;
	}
	.mediaUploadFTable>ul>li:nth-child(2) {
		width: 26%;
	}
	.mediaUploadFTable .drogAndDropG > ul > li:nth-child(1),
	.mediaUploadFTable .drogAndDropG > ul > li:nth-child(2) {
		font-size: 11px;
	}
	.mediaUploadFTable .drogAndDropG .DragScreensaverhere,
	.mediaUploadFTable .drogAndDropG .DragPreparationhere {
		padding: 5px 0px 0px 28px;
		overflow: hidden;
		max-width: 200px;
	} 
	.navbarLeft > li .navStoreText,
	.cbcategory > ul > li > .cbcategoryBox > .navTitle,
	.mediaUploadFTable > ul > li:nth-child(2) > button,
	.assignedUnitGrpsTable > ul > li,
	.myInformationTable > ul > li h4,
	.myInformationTable > ul > li h5,
	.menuUpdateStatusExpTable .menuUpdateExpTable > ul > li,
	.menuUpdateStatusExpTable > ul > li,
	.uploadHistoryExpTable > ul > li,
	.updateStatusExpTable > ul > li,
	.updateStatusExpTable > ul > li h4 {
		font-size: 14px;
	} 

	.navbarLeft > li .navStoreText > b,
	.menuAssignFTable > ul > li:nth-child(2) > button .menuAFileS,
	.newReportWrapperR .maxDuration,
	.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedText h6,
	.updateStatusExpTable > ul > li h5 {
		font-size: 12px;
	}
	.recipeEditorGrid.frymasterBrandRC {
		padding: 10px 20px; 
		background:none;
	}
	.recipeEditorGrid > ul > li,
	.recipeEditorGrid.frymasterBrandRC > ul > li{
		width:100%; 
		padding: 0px 5px;
		background: url("../images/recipecreation/borderBg.png") no-repeat bottom center;
		background-size: 90% 1px;
	}
	.recipeEditorGrid > ul > li:last-child,
	.recipeEditorGrid.frymasterBrandRC > ul > li:last-child{ 
		background:none;
	}
	.recipeEditorGrid > ul > li > .btn{
		padding: 15px 0px 15px 50px;
		text-align: left;
	} 
	.recipeEditorGrid > ul > li .recipeCreationNav {
		background: url("../images/recipecreation/recipeCreationA.png") no-repeat left center;
		background-size: 48px 48px;
	}
	.recipeEditorGrid > ul > li .cookbookNav {
		background: url("../images/recipecreation/cookbookA.png") no-repeat left center;
		background-size: 48px 48px;
	}
	.recipeEditorGrid > ul > li .settingsNav {
		background: url("../images/recipecreation/settingsA.png") no-repeat left center;
		background-size: 48px 48px;
	}
	.recipeEditorGrid > ul > li .menusNav {
		background: url("../images/recipecreation/menuCreation.png") no-repeat left center;
		background-size: 48px 48px;
	}
	.recipeEditorGrid > ul > li .informationNav {
		background: url("../images/recipecreation/informationIcon.png") no-repeat left center;
		background-size: 48px 48px;
	}
	.recipeEditorGrid > ul > li .helpNav {
		background: url("../images/recipecreation/helpIcon.png") no-repeat left center;
		background-size: 48px 48px;
	}
}
@media only screen and (min-width: 480px) and (max-width: 568px) {  
	.stConfirmBox > ul > li,
	.uploadedUnitsList.samrtTagentrys > ul > li {
		width: 33.33%;
		float:left;
	}
	.stConfirmBox .unitsWithST, 
	.stConfirmBox .unitsWithoutST, 
	.stConfirmBox .errorsST,
	.uploadedUnitsList .registeredDevices,
	.uploadedUnitsList .existingDevices,
	.uploadedUnitsList .duplicateEntry,
	.uploadedUnitsList .wrongEntry,
	.uploadedUnitsList .removedEntry { 
		width:100%;
		height:auto;
		border-radius:0;
		padding:5px;  
	}
	.orgPurposeTable > ul > li:nth-child(2){
		width:35%;
	}
	.navbarLeft.fivePillarsHeader > li .navStoreText {
		width: 180px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 14px;
	}
	.navbar-dark .navbar-nav.titlebarLeft .nav-link {
		width:180px;
		font-size: 14px;  
	}
	.kcHowToTable > ul > li { 
		padding:0px 15px 20px 0px; 
		width:100%;
		float:left;
	}
	.modal-dialog.modal-md {
		margin: 0px;
	}
	.selectDeviceGrid > ul > li,
	.selectModelGrid > ul > li {
		width: 32%;
	} 
	.gridView .colm3D {
		float: left;
		width: 100%;
	}
	.gridViewTable {
		padding: 0px 2px;
	}
	.gridView .colm2D,
	.gridView .colm3D,
	.gridView .colm4D,
	.gridView .colm6D,
	.gridView .colm7D,
	.gridView .colm8D,
	.gridView .colm9D,
	.gridView .colm10D,
	.gridView .colm12D {
		border-left: 0px;
		border-right: 0px;
	}
	.mainMenuGrid > ul > li,
	.allLocationsGrid > ul > li,
	.allUnitsGrid > ul > li {
		width: 50%;
	} 
	.unitQualityPrncTable > ul > li:nth-child(2),
	.unitQualityPrncExpTable > ul > li:nth-child(2),
	.unitQualityPrncTable > ul > li:nth-child(3),
	.unitQualityPrncExpTable > ul > li:nth-child(3),
	.unitQualityPrncTable > ul > li:nth-child(4),
	.unitQualityPrncExpTable > ul > li:nth-child(4),
	.unitQualityPrncTable > ul > li:nth-child(6),
	.unitQualityPrncExpTable > ul > li:nth-child(6),
	.userMgmtListTable > ul > li:nth-child(1), 
	.userMgmtListTable > ul > li:nth-child(4),
	.userMgmtListTable > ul > li:nth-child(5),
	.userMgmtListTable > ul > li:nth-child(6),
	.userMgmtListTable > ul > li:nth-child(7) {
		display: none;
	}
	.unitQualityPrncTable > ul > li:nth-child(5),
	.unitQualityPrncExpTable > ul > li:nth-child(5) {
		width: 110px;
		padding-left: 0px;
		padding-right: 0px;
	}
}
@media only screen and (min-width: 568px) and (max-width: 765px) { 
	.stConfirmBox,
	.uploadedUnitsList{
		margin:0px;
		width:100%;
	}
	.stConfirmBox > ul > li,
	.uploadedUnitsList.samrtTagentrys > ul > li {
		width: 33.33%;
		float:left;
	}
	.stConfirmBox .unitsWithST, 
	.stConfirmBox .unitsWithoutST, 
	.stConfirmBox .errorsST,
	.uploadedUnitsList .registeredDevices,
	.uploadedUnitsList .existingDevices,
	.uploadedUnitsList .duplicateEntry,
	.uploadedUnitsList .wrongEntry,
	.uploadedUnitsList .removedEntry { 
		width:100%;
		height:auto;
		border-radius:0;
		padding:5px;  
	} 
	.tableHNav > ul > li a{
		float:left;
		width:50%;
		text-align:center;
		margin:0px;
		padding:10px 0px;
	}  
	.tableHNav > ul > li:nth-child(1) a:nth-child(3){ 
		width:100%; 
		border-top: 1px solid #7e8690;
	} 
	.tableHNav > ul > li:nth-child(2){ 
		width:100%; 
		border-top: 1px solid #7e8690;
		text-align:center;
		padding:10px 0px 10px;
	}  
	.createdEditedDetailsO > ul > li,
	.tableHNav > ul > li{
		float:left;
		width:100%;
	} 
	.newReportNS.forMObileR {
		display:block;
	}
	.unitRegCList > ul.tHead > li:nth-child(7) {
		width: 220px; 
	}
	.unitRegCList > ul > li:nth-child(7) {
		width: 208px;
		text-align: left;
	}  
	.stLocationTable > ul > li:nth-child(3),
	.SWlistUnitsTable > ul > li:nth-child(8), 
	.SWlistUnitsTable > ul > li:nth-child(9), 
	.SWuStatusTable > ul > li:nth-child(8), 
	.unitRegCList > ul > li:nth-child(8),
	.newReportNS.forDesktopR,
	.selectRecipeTable > ul > li:nth-child(1),
	.selectRecipeTable > ul > li:nth-child(4),
	.selectRecipeTable > ul > li:nth-child(6),
	.selectRecipeTable > ul > li:nth-child(8),
	.unitQualityPrncTable > ul > li:nth-child(2),
	.unitQualityPrncExpTable > ul > li:nth-child(2),
	.unitQualityPrncTable > ul > li:nth-child(3),
	.unitQualityPrncExpTable > ul > li:nth-child(3),
	.unitQualityPrncTable > ul > li:nth-child(6),
	.unitQualityPrncExpTable > ul > li:nth-child(6),
	.disposeInformationTable > ul > li:nth-child(1),
	.disposeInformationTable > ul > li:nth-child(5),
	.disposeInformationTable > ul > li:nth-child(6),
	.cleaningDataTable > ul > li:nth-child(1),
	.cleaningDataTable > ul > li:nth-child(5),
	.cookingDataTable > ul > li:nth-child(1),
	.listLocationsTable > ul > li:nth-child(1),
	.listLocationsTable > ul > li:nth-child(4),
	.listLocationsTable > ul > li:nth-child(5),
	.listLocationsTable > ul > li:nth-child(6),
	.haccpUpdateStatusTable > ul > li:nth-child(1),
	.haccpUpdateStatusTable > ul > li:nth-child(6),
	.haccpUpdateStatusTable > ul > li:nth-child(7),
	.updateStatusTable > ul > li:nth-child(1),
	.updateStatusTable > ul > li:nth-child(5),
	.updateStatusTable > ul > li:nth-child(7), 
	.updateStatusTable > ul > li:nth-child(9),
	.mediaStatus > ul > li:nth-child(1),
	.mediaStatus > ul > li:nth-child(5),
	.mediaStatus > ul > li:nth-child(7),  
	.menuAStatusTable > ul > li:nth-child(2),
	.menuAStatusTable > ul > li:nth-child(3),
	.menuAStatusTable > ul > li:nth-child(8),
	.menuAStatusTable > ul > li:nth-child(9),
	.menuAStatusTable > ul > li:nth-child(11),
	.userMgmtListTable > ul > li:nth-child(1), 
	.userMgmtListTable > ul > li:nth-child(4),
	.userMgmtListTable > ul > li:nth-child(5),
	.userMgmtListTable > ul > li:nth-child(6),
	.statusTable > ul > li:nth-child(12),
	.statusTable > ul > li:nth-child(2),
	.statusTable > ul > li:nth-child(5),
	.statusTable > ul > li:nth-child(6),
	.statusTable > ul > li:nth-child(9), 
	.statusTable > ul > li:nth-child(10){
		display:none;
	} 
	.navbarRight{
		border-left:0px;
	}
	.kcHowToTable > ul > li { 
		padding:0px 15% 20px 0px; 
		width:100%;
		float:left;
	}
	.cookieWrapper{
		padding: 10px 0px 3px;
		bottom: 60px;
	}
	.LoginBtnWrapper{
		bottom: 95px;
	}
	.supportButtonWrapper{
		margin:5px 0px;
	}
	.formWrapperL,
	.formWrapperR,
	.newReportWrapperL,
	.newReportWrapperR {
		width: 100%;
		float:left;
		padding-right: 0px;
		min-height: auto;
	}
	.reportTypeList {
		padding: 10px 0px;
	}
	.formWrapperR,
	.newReportWrapperR {
		padding: 10px 0px 10px 0px;
		border-left: none;
	} 
	.newReportWrapper {
		padding: 10px 10px;
	}
	.newReportForm > h6,
	.reportTypeList > h6 {
		padding: 0px 0px 10px;
	}
	.newReportWrapperR .maxDuration {
		font-size: 12px;
	}
	.reportTypeList > ul > li .ErrorReportIcon {
		width: 100%;
		float: none;
		clear: both;
		padding: 0px 0px 0px 0px;
	}
	.newReportForm > ul > li .input-group,
	.newReportForm > ul > li > label {
		clear: none;
	} 
	.billingFormWrapper > ul > li:nth-child(1),
	.billingFormWrapper > ul > li,
	.billingForm > ul > li.colm50,
	.billingForm > ul > li.colm33,
	.billingForm > ul > li{
		float:left;
		width:100%;
	} 
	.billingForm > ul > li:nth-child(2),
	.billingForm > ul > li:nth-child(3) {
		padding-left: 0px;
	}
	.btn.billingFormRegister{
		float:inherit;
		margin:15px 25%;
		width:50%; 
	}
	.rcDeviceWrapper {
		float: left;
		width: 100%;
		clear: both;
		margin: 95px 0px 0px;
	}
	.testOnUnitTable,
	.recipeEditorWrapper .recipeEditorF,
	.recipeEditorWrapper .recipeEditorH,
	.recipeEditorWrapper {
		border-radius: 0px;
	}
	.rcDeviceWrapper .footerText {
		padding: 5px 10px;
		text-align: center;
	}
	.unitQualityPrncTable > ul > li:nth-child(4),
	.unitQualityPrncExpTable > ul > li:nth-child(4) {
		width: 32%;
		padding-left: 0px;
		padding-right: 0px;
	}
	.unitQualityPrncTable > ul > li:nth-child(5),
	.unitQualityPrncExpTable > ul > li:nth-child(5) {
		width: 28%;
		padding-left: 0px;
		padding-right: 0px;
	}
	.filtrationDataTable > ul > li:nth-child(6),
	.filtrationDataExpTable > ul > li:nth-child(6),
	.productMixTable > ul > li:nth-child(5),
	.productMixExpTable > ul > li:nth-child(5) {
		width: 90px;
		padding-left: 0px;
		padding-right: 0px;
	}
	.productMixTable > ul > li:nth-child(2),
	.productMixExpTable > ul > li:nth-child(2) {
		width: 180px;
	} 
	.cookingDataTable > ul > li:nth-child(4),
	.cookingDataTable > ul > li:nth-child(5) {
		width: auto;
		min-width: 120px;
		text-align: center;
	}
	.cookingDataTable > ul > li:nth-child(3) {
		padding: 10px 0px;
		width: auto;
		min-width: 100px;
	}
	.selectRecipeTable > ul > li .unitsRCSM,
	.selectRecipeTable > ul > li .unitsRCMD,
	.listLocationsTable > ul.tBody > li .locationsListSM,
	.haccpUpdateStatusTable > ul.tBody > li .updateStatusSM,
	.haccpUpdateStatusTable > ul.tBody > li .updateStatusSB,
	.updateStatusTable > ul.tBody > li .updateStatusSM,
	.updateStatusTable > ul.tBody > li .updateStatusSB,
	.mediaStatus > ul.tBody > li .updateStatusSM,
	.mediaStatus > ul.tBody > li .updateStatusSB,
	.menuAStatusTable > ul.tBody > li .menuAStatusSM,
	.menuAStatusTable > ul.tBody > li .menuAStatusSB {
		display: block;
		font-size: 12px;
	}
	.haccpUpdateStatusTable > ul > li:nth-child(2),
	.updateStatusTable > ul > li:nth-child(2),
	.mediaStatus > ul > li:nth-child(2) {
		padding-left: 10px;
	}
	.disposeInformationTable > ul > li:nth-child(4) {
		width: 60px;
		padding-left: 0px;
		padding-right: 0px;
	}
	.productMixTable > ul > li:nth-child(4),
	.productMixExpTable > ul > li:nth-child(4),
	.disposeInformationTable > ul > li:nth-child(7) {
		width: 110px;
	}
	.selectRecipeTable > ul > li:nth-child(2) {
		padding-left: 10px;
	}
	.selectRecipeTable > ul > li:nth-child(3) {
		width: 200px;
	} 
	.newReportWrapper {
		padding: 10px 10px;
	}
	.reportTypeList,
	.formWrapperR,
	.newReportWrapperR {
		padding: 10px 0px;
	}
	.newReportForm > h6,
	.reportTypeList > h6 {
		padding: 0px 0px 10px;
	}
	.listLocationsTable > ul > li:nth-child(2),
	.listLocationsTable > ul > li:nth-child(8) {
		width: 50px;
	}
	.listLocationsTable > ul > li:nth-child(3),
	.listLocationsTable > ul > li:nth-child(4),
	.listLocationsTable > ul > li:nth-child(5) {
		width: auto;
	}
}
@media only screen and (min-width: 568px) and (max-width: 834px) { 
	.navHeader > ul > li:nth-child(2),
	.orgznMgmtHeader > ul > li:nth-child(2),
	.orgHeader > ul > li:nth-child(2){
		float:left;
		width:100%;
		max-width:100%;
		text-align:center;
		padding:10px 0px 10px;
		border-top: 1px solid #7e8690;
	}
	.navHeader > ul > li:nth-child(1), 
	.orgznMgmtHeader > ul > li:nth-child(1), 
	.orgHeader > ul > li:nth-child(1){
		float:left;
		width:100%;
	} 
	.bakingPlansList > ul.tHead > li:nth-child(5) {
		width: 130px;
		text-align: center;
	}
	.bakingPlansList > ul > li:nth-child(5){
		width: 118px;	
	}
	.orgPurposeTable > ul > li:nth-child(2){
		width:35%;
	}
	.reportHeader > ul > li:nth-child(1) > a{
		float:left;
		width:50%;
		text-align:center;
		margin:0px;
		padding:10px 0px;
	}  
	.reportHeader > ul > li:nth-child(2){
		float:left;
		width:100%;
		text-align:center;
		padding:10px 0px 10px;
		border-top: 1px solid #7e8690;
	}
	.reportHeader > ul > li .checkBoxListGrp {
		float:left;
		width:100%;
		text-align:center;
		padding:0px 0px 10px;
		border-bottom: 1px solid #7e8690;
		margin-bottom:10px;
	}
	.registerUnitLtable > ul > li { 
		border-bottom: 1px solid #fff;
		border-left: 1px solid #fff;
	}
	.billingOForm > ul > li:nth-child(1){
		width:55%;
	}
	.reportHeader > ul > li:nth-child(1),
	.billingFormWrapper > ul > li:nth-child(1),
	.billingFormWrapper > ul > li, 
	.billingPlanForm > ul > li.colm50,
	.billingPlanForm > ul > li.colm33,
	.billingPlanForm > ul > li{
		float:left;
		width:100%;
	}
	.billingPlanForm > ul > li:nth-child(2),
	.billingPlanForm > ul > li:nth-child(3){
		padding-left:0px;
	}
	.subscriptionForm .mCSB_inside > .mCSB_container {
		margin-right: 15px;
	}
	.subscriptionList.subscriptionH, 
	.subscriptionList.subscriptionFooter {
		padding-right: 15px;
	} 
	.subscriptionForm {
		width: 100%;
		float:left;
		clear: both;
	}
	.subscriptionPlanPro h4, .subscriptionPlanBasic h4 { 
		font-size: 14px; 
	}
	.eaasWrapper,
	.deviceRegisterWrapper,
	.unitRegSuccess{
		width: 480px;
		float: none;
		margin: 0px auto;
		padding:0px;
	}
	.thankYouEAAS { 
		margin: 80px 15px 0px;
		background: url("../images/whiteTransparent.png") repeat;
	} 
	.signinupDiv .signInDiv, .signinupDiv .signUpDiv,
	.existingLoginForm {
		background: url("../images/whiteTransparent.png") repeat;
		border:0px;
	} 
	.unitRegFormWrapper { 
		padding: 0px 15px;
	} 
	.unitRegSuccess .unitRegThanksCnt > img,
	.thankYouEAAS .thankYouEAASCnt > img { 
		max-width: 80px;
	}
	.filtrationDataTable > ul > li:nth-child(3),
	.filtrationDataExpTable > ul > li:nth-child(3),
	.filtrationDataTable > ul > li:nth-child(4),
	.filtrationDataExpTable > ul > li:nth-child(4),
	.filtrationDataTable > ul > li:nth-child(5),
	.filtrationDataExpTable > ul > li:nth-child(5) {
		width: 110px;
		text-align: center;
	}
	.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedText h5 {
		font-size: 14px;
		width: 120px;
	}
	.mediaUploadFTable .dragedDetailsTable .dragedDetailsRow .dragedText h6 {
		font-size: 12px;
	}
	.mediaUploadFTable > ul > li:nth-child(2) > button {
		padding: 30px 0px;
		font-size: 14px;
	}
	.gridViewAMcolm3D6DSM .gridView .colm6D,
	.gridViewAMcolm6DSM .gridView .colm6D {
		float: left;
		width: 100%;
	}
	.gridViewAMcolm3D6DSM .gridView .colm3D {
		float: left;
		width: 50%;
	}
	.gridView .gridTableView.gridTableViewSW > ul > li {
		padding-left: 0px;
	}
	.gridViewTable.gridViewAMcolm6DSM .gridView .gridTableView > ul > li > ul > li > label {
		padding-left: 0px;
		width: 190px;
	}
	.gridView .gridTableView.gridTableViewSW > ul > li > ul > li > label {
		padding-left: 0px;
		width: 170px;
	}
	.gridViewSMcolm39DSM .gridView .colm9D,
	.gridViewSMcolm39DSM .gridView .colm3D {
		width: 50%;
		float: inherit;
		min-height: auto;
	}
	.gridViewcolm4DSM .gridView .colm4D {
		float: left;
		width: 50%;
		min-height: 190px;
	}
	.gridViewTable.gridViewcolm4DSM > .gridView,
	.gridViewTable.gridViewcolm4DSM {
		width: inherit;
		display: initial;
		padding: 0px;
		float: inherit;
	}
	.gridViewTable.gridViewcolm4DSM:first-child > .gridView .colm4D.colm4D1 {
		border-left: 0px;
	}
	.gridViewTable.gridViewcolm4DSM > .gridView .colm4D.colm4D1 {
		border-left: 1px solid #000;
	}
	.gridViewTable.gridViewcolm4DSM:first-child > .gridView .colm4D:nth-child(2) {
		border-right: 0px;
	}
	.gridViewTable.gridViewcolm4DSM .gridView .colm4D.colm4D3 {
		border-right: 1px solid #000;
	}
	.navbarLeft.fivePillarsHeader > li .navStoreText {
		font-size: 14px;
	}
	.navbarLeft.fivePillarsHeader > li .navStoreText > b {
		font-size: 12px;
		display: inline;
	}
	.navbarLeft.fivePillarsHeader > li {
		width: auto;
		word-break: break-word;
		padding: 5px 10px 4px;
	}
	.navbar-dark .navbarLRight.timeZoneSNheader a {
		padding-right: 10px;
	}
	.navbarLeft.fivePillarsHeader > li:first-child {
		border-right: 0px;
	}
	.navbarLeft > li .navStoreIcon { 
		width: 22px;
		line-height: 22px; 
	}
	.navbarLeft > li .navStoreIcon > img {
		max-width: 22px;
		max-height: 22px;
	} 
	.gridView .colm3D {
		float: left;
		width: 50%;
		min-height: 210px;
	}
	.mainMenuGrid > ul > li,
	.allLocationsGrid > ul > li,
	.allUnitsGrid > ul > li {
		width: 33.33%;
	} 
	.fErrorListTable > ul.tBody > li .errorTypeSM,
	.errorListTable > ul.tBody > li .errorTypeSM {
		display: inline-block;
	}
	.bakingPlansList > ul.tBody > li .bakingPlansSM,
	.readyRecipeTable > ul.tBody > li .menuSListSM,
	.readyRecipeTable > ul.tBody > li .menuSListSMF,
	.fErrorListTable > ul.tBody > li .errorListSM,
	.errorListTable > ul.tBody > li .errorListSM,
	.ntnLibraryTable > ul.tBody > li .unitsListSM,
	.ntnLibraryTable > ul.tBody > li .unitsListSML,
	.mediaPreparationTable > ul.tBody > li .mediaSListSM,
	.mediaPreparationTable > ul.tBody > li .mediaSListAS,
	.mediaScreensaverTable > ul.tBody > li .mediaSListSM,
	.mediaScreensaverTable > ul.tBody > li .mediaSListAS,
	.mediaCompilationTable > ul.tBody > li .mediaSListSM,
	.mediaCompilationTable > ul.tBody > li .mediaSListAS,
	.SWlistUnitsTable > ul.tBody > li .unitsListSM,
	.reportAsgnList > ul.tBody > li .unitsListSM,
	.unitRegListTable > ul.tBody > li .unitsListSM,
	.unitRegCList > ul.tBody > li .unitsListSM,
	.STComfmtList > ul.tBody > li .unitsListSM,
	.STComfmtList > ul.tBody > li .unitsSM,
	.listUnitsTable > ul > li .unitsListSM {
		display: block;
		font-size: 12px;
	}
	.SWuStatusTable > ul.tBody > li .SWuStatusSM,
	.SWuStatusTable > ul.tBody > li .SWuStatusAN,
	.orgUnitsList > ul.tBody > li .unitsListAN,
	.orgUnitsList > ul.tBody > li .unitsListSM, 
	.orgUnitsList > ul.tBody > li .unitsListSML, 
	.unitRegListTable > ul.tBody > li .unitsListAN, 
	.unitRegCList > ul.tBody > li .unitsListAN {
		display: block;
	}  
	.orgTable > ul > li:nth-child(5) {
		width: 110px;
	} 
	.SWuStatusTable > ul > li:nth-child(6) {
		width: 140px;
	} 
	.orgUnitsList > ul > li:nth-child(3),
	.orgTable > ul > li:nth-child(3){
		padding-left:0px;
	}
	.menuUpdateStatusExpTable > ul > li:nth-child(2),
	.unitRegListTable > ul > li:nth-child(2),
	.unitRegCList > ul > li:nth-child(2) {
		padding-left: 10px;
	}
	.SWlistUnitsTable > ul > li:nth-child(2),
	.reportAsgnList > ul > li:nth-child(2),
	.listUnitsTable > ul > li:nth-child(2),
	.listLocationsTable > ul > li:nth-child(2) {
		padding: 7px 0px;
	}
	.SWlistUnitsTable > ul > li:nth-child(3),
	.reportAsgnList > ul > li:nth-child(3),
	.listUnitsTable > ul > li:nth-child(3) {
		padding-left: 0px;
	}
	.SWlistUnitsTable > ul.tBody > li:nth-child(2) > img,
	.reportAsgnList > ul.tBody > li:nth-child(2) > img,
	.listUnitsTable > ul > li:nth-child(2) > img,
	.listLocationsTable > ul.tBody > li:nth-child(2) > img {
		width: 36px;
	} 
	.disposeInformationTable > ul > li:nth-child(4) {
		width: 60px;
		padding-left: 0px;
		padding-right: 0px;
	}
	.disposeInformationTable > ul > li:nth-child(6),
	.disposeInformationTable > ul > li:nth-child(7) {
		width: 110px;
	}
	.allErrorListTable > ul > li:nth-child(6){
		width:auto;
	} 
	.allErrorListTable > ul > li:nth-child(5),
	.allErrorListTable.allHErrorListTable > ul > li:nth-child(6){
		width:160px;
	}
	.STComfmtList > ul > li:nth-child(2) { 
		width: 30%;
	}
	.bakingPlansList > ul > li:nth-child(2),
	.bakingPlansList > ul > li:nth-child(3), 
	.readyRecipeTable > ul > li:nth-child(5), 
	.readyRecipeTable > ul > li:nth-child(6),
	.readyRecipeTable > ul > li:nth-child(7),
	.SWuStatusTable > ul > li:nth-child(4),
	.SWuStatusTable > ul > li:nth-child(5),
	.SWuStatusTable > ul > li:nth-child(7),
	.SWuStatusTable > ul > li:nth-child(11),
	.SWuStatusTable > ul > li:nth-child(12),
	.SWuStatusTable > ul > li:nth-child(14),
	.allErrorListTable > ul > li:nth-child(2), 
	.allErrorListTable > ul > li:nth-child(7),
	.allErrorListTable > ul > li:nth-child(8),
	.allErrorListTable > ul > li:nth-child(9),
	.allErrorListTable > ul > li:nth-child(10),
	.allErrorListTable.allHErrorListTable > ul > li:nth-child(7),
	.allErrorListTable.allHErrorListTable > ul > li:nth-child(8),
	.allErrorListTable.allHErrorListTable > ul > li:nth-child(9),
	.allErrorListTable.allHErrorListTable > ul > li:nth-child(10),
	.allErrorListTable.allHErrorListTable > ul > li:nth-child(11),
	.orgTable > ul > li:nth-child(6),
	.errorListTable > ul > li:nth-child(2),
	.errorListTable > ul > li:nth-child(4),
	.errorListTable > ul > li:nth-child(6), 
	.fErrorListTable > ul > li:nth-child(2),
	.fErrorListTable > ul > li:nth-child(4),
	.fErrorListTable > ul > li:nth-child(5), 
	.fErrorListTable > ul > li:nth-child(7),
	.errorListTable.historicErrorsTable > ul > li:nth-child(7),
	.errorListTable.historicErrorsTable > ul > li:nth-child(8),  
	.fErrorListTable.historicErrorsTable > ul > li:nth-child(8),
	.fErrorListTable.historicErrorsTable > ul > li:nth-child(9), 
	.disposeInformationTable > ul > li:nth-child(1),
	.disposeInformationTable > ul > li:nth-child(5), 
	.utilizationExpTable > ul > li:nth-child(2),
	.utilizationExpTable > ul > li:nth-child(5),
	.utilizationTable > ul > li:nth-child(2),
	.utilizationTable > ul > li:nth-child(5),
	.mediaAllTable > ul > li:nth-child(3),
	.mediaAllTable > ul > li:nth-child(5),
	.mediaAllTable > ul > li:nth-child(6),
	.mediaAllTable > ul > li:nth-child(7),
	.mediaPreparationTable > ul > li:nth-child(1),
	.mediaPreparationTable > ul > li:nth-child(4),
	.mediaPreparationTable > ul > li:nth-child(5),
	.mediaPreparationTable > ul > li:nth-child(8),
	.mediaScreensaverTable > ul > li:nth-child(1),
	.mediaScreensaverTable > ul > li:nth-child(4),
	.mediaScreensaverTable > ul > li:nth-child(5),
	.mediaScreensaverTable > ul > li:nth-child(8),
	.mediaCompilationTable > ul > li:nth-child(1),
	.mediaCompilationTable > ul > li:nth-child(4),
	.mediaCompilationTable > ul > li:nth-child(8),
	.menuUpdateStatusExpTable > ul.tBody > li:nth-child(1),
	.menuUpdateStatusExpTable > ul.tBody > li:nth-child(3),
	.panel-body.uploadHistoryExpTable > ul > li:nth-child(1),
	.panel-body.uploadHistoryExpTable > ul > li:nth-child(2),
	.panel-body.uploadHistoryExpTable > ul > li:nth-child(4),
	.panel-body.updateStatusExpTable > ul > li:nth-child(1),
	.unitRegListTable > ul > li:nth-child(1),
	.unitRegListTable > ul > li:nth-child(3),
	.unitRegListTable > ul > li:nth-child(4),
	.unitRegListTable > ul > li:nth-child(5),
	.unitRegCList > ul > li:nth-child(1),
	.unitRegCList > ul > li:nth-child(3),
	.unitRegCList > ul > li:nth-child(4),
	.unitRegCList > ul > li:nth-child(5),
	.STComfmtList > ul > li:nth-child(3),
	.STComfmtList > ul > li:nth-child(5),
	.STComfmtList > ul > li:nth-child(7),
	.listUnitsTable > ul > li:nth-child(4),
	.listUnitsTable > ul > li:nth-child(5),
	.listUnitsTable > ul > li:nth-child(9),
	.SWlistUnitsTable > ul > li:nth-child(4),
	.SWlistUnitsTable > ul > li:nth-child(5), 
	.reportAsgnList > ul > li:nth-child(4),
	.reportAsgnList > ul > li:nth-child(5),
	.reportAsgnList > ul > li:nth-child(9),
	.reportAsgnList > ul > li:nth-child(10),
	.ntnLibraryTable > ul > li:nth-child(3),
	.ntnLibraryTable > ul > li:nth-child(4),
	.ntnLibraryTable > ul > li:nth-child(5),
	.ntnLibraryTable > ul > li:nth-child(6),
	.listLocationsTable > ul > li:nth-child(1) {
		display: none;
	} 
	.reportAsgnList > ul > li:nth-child(6),
	.reportAsgnList > ul > li:nth-child(7),
	.listUnitsTable > ul > li:nth-child(6),
	.listUnitsTable > ul > li:nth-child(7) {
		min-width: 120px;
		width: auto;
		padding: 7px 5px;
	}
	.listLocationsTable > ul > li:nth-child(7) {
		width: 80px;
		padding: 7px 0px;
	}
	.unitRegListTable > ul > li:nth-child(7),
	.unitRegListTable > ul > li:nth-child(8) {
		width: 100px;
		padding: 7px 0px;
	}
	.unitRegListTable > ul > li:nth-child(5) {
		padding: 7px 0px;
	}
	.countryCheckbox > .customCheckbox {
		width: 48%;
	}
	.mediaHeader > ul > li {
		padding: 8px 15px 9px;
	}
}
@media only screen and (min-width: 320px) and (max-width: 765px) {
	.listUnitsTable > ul.tHead > li:nth-child(10){
		width:70px;
	}
	.listUnitsTable > ul > li:nth-child(10){
		width:58px;
	}
	.unitRegUnitDetails .smartTagsList,
	.smartTagsList,
	.unitRegForm.floatLeft {
		float: left;
		width:100%;
	}
	
	.loadingProgressInText { 
		margin: 0px 0px -95px 0px; 
	}
	.installOption.marginTopNeg{
		margin-top:0px;
	}
	.conectivityWrapper {
		width: 96%;
		margin:15px 2% 0px;  
		border-radius:0px;
	} 
	.conectivityWrapperL{
		width:auto;
		max-width:340px;
		margin:0px auto;
		padding:15px;
		float: none; 
	} 
	.conectivityWrapperR {
		width: 100%;
		padding:15px;
		float: left; 
	}
	.conectivityWrapperR { 
		background: url("../images/icons/topDivider.png") no-repeat top center;
		background-size:100% 1px;
	}
	.resultWillbeD { 
		top: 60px; 
		margin-top: 0px; 
	}  
	.subscriptionBForm {
		width:100%;
		float:left; 
	}
	.billingForm > ul > li.colm50:nth-child(1),
	.billingForm > ul > li.colm50:nth-child(2) {
		width:100%;
		float:left; 
		padding-right:0px;
		padding-left:0px;
	}
	.orgTable > ul.tBody > li .orgListSM,
	.subscriptionWrapper .btn.planToggleBtn {  
		display:block;
	}  
	.menuLbryLTable > ul > li:nth-child(3) { 
		width:auto;
	}  
	.menuBrandsTable > ul > li:nth-child(3),
	.brandsLibraryTable > ul > li:nth-child(3),
	.orgPurposeTable > ul > li:nth-child(3),
	.orgTable > ul > li:nth-child(4),
	.menuLbryLExpTable > ul > li:nth-child(3),
	.menuLbryLTable > ul > li:nth-child(2), 
	.menuLbryLTable > ul > li:nth-child(6), 
	.menuLbryLTable > ul > li:nth-child(7),  
	.subscriptionList > ul > li:nth-child(2),
	.subscriptionList > ul > li:nth-child(3) { 
		display:none;
	}  
	.selectRecipeModal.selectLiberaryModal .modal-dialog.modal-lg,
	.selectRecipeModal .modal-dialog.modal-lg,
	.modal-dialog.modal-lg.frypotInfoModal,
	.termsConditionsModal .modal-lg {
		min-width:98%; 
	}
}
@media only screen and (min-width: 320px) and (max-width: 834px) { 
	.newReportWrapper div.react-datepicker-popper[data-placement^=bottom],	
	.newReportWrapper .react-datepicker-popper{
		right: 0px;
		left: inherit !important;
	}
	.newReportForm .endDatePicker div.react-datepicker-popper[data-placement^=bottom],
	.newReportForm .endDatePicker .react-datepicker-popper{ 
		left: -62px !important;
	}
	.unitQualityPrncTable > ul > li:nth-child(1) {
		padding-left: 0px;
		min-width: 100px;
	}
	.unitQualityPrncExpTable > ul > li:nth-child(1) {
		padding-left: 10px;
		min-width: 100px;
	}
	.TPMValueTable > ul > li {
		display: none;
	}
	.TPMValueTable > ul > li:nth-child(2) {
		display: table-cell;
	}
	.menuLibraryTable > ul.tHead > li:nth-child(10),
	.menuLbryMcfTable > ul.tHead > li:nth-child(9) {
		text-indent: -9999px;
	}
	.menuLibraryTable > ul > li:nth-child(10),
	.menuLbryMcfTable > ul > li:nth-child(9) {
		padding-left: 0px;
		padding-right: 0px;
		width: 30px;
	}
	.menuLibraryTable > ul > li .badge,
	.menuLbryMcfTable > ul > li .badge{
		float: none;
		margin-left: 10px;
	}
	.SWlistUnitsTable > ul.tBody > li .unitsListAN,
	.menuLibraryTable > ul.tBody > li .menuSListSM,
	.menuLibraryTable > ul.tBody > li .menuSListSMF,
	.menuLibraryTable > ul.tBody > li .menuSListRS,
	.menuLbryMcfTable > ul.tBody > li .menuSListSM,
	.menuLbryMcfTable > ul.tBody > li .menuSListSMF,
	.menuLbryMcfTable > ul.tBody > li .menuSListRS,
	.TPMValueTable > ul > li:nth-child(2) .frypotWiseSM {
		display: block;
	}
	.addUserForm>ul>li .form-control.countryCode {
		width: 30%;
	}
	.addUserForm>ul>li .form-control.phoneNumber {
		width: 68%;
	}
	.mediaLibraryTable .previewBtn {
		position: static;
		bottom: inherit;
		right: inherit;
		float: left;
	}
	.mediaLibraryTable > ul > li > ul > li > label {
		width: 80px;
	}
	.gridView .screensaverSize .mediaTextH3,
	.gridView .preparationFileSize .mediaTextH3,
	.gridView .spaceAvailable .mediaTextH3,
	.gridView .screensaverSize .mediaTextH2,
	.gridView .preparationFileSize .mediaTextH2,
	.gridView .spaceAvailable .mediaTextH2 {
		font-size: 12px;
	}
	.mediaULHeader > ul > li a {
		margin-right: 10px;
		padding: 8px 15px 9px;
	}
	.softwareUpdateHeader > ul > li a:last-child {
		margin-right: 0px;
	}
	.pageTabHeader > ul > li {
		padding: 0px 10px;
	}
	.pageTabHeader > ul > li.selectedBox {
		padding: 10px 0px;
	}
	.pageHeader > ul > li > a {
		padding: 10px 15px 10px;
	} 
	.filterListOuter {
		padding: 10px 10px 0px;
	}
	.scheduleUpdateWrapperR,
	.scheduleUpdateWrapperL {
		width: 100%;
		padding-right: 0px;
		min-height: auto;
	}
	.scheduleUpdateWrapperR {
		padding: 10px 0px;
		border-left: 0;
	}
	.scheduleUpdateWrapper {
		padding: 15px;
	}
	.scheduleUpdateOption > .customRadioBtn {
		margin: 10px 0px 10px;
	}
	.scheduleUpdateForm {
		padding: 0px 0px 5px 0px;
	}
	.userMgmtRolesTable > ul > li:nth-child(2),
	.userMgmtListTable > ul > li:nth-child(2) {
		padding-left: 10px;
		width: 60px;
	}
	.userMgmtListTable > ul > li:nth-child(3) {
		width: auto;
		min-width: 180px;
	} 
	.gridView .colmDi > .selectedDiviceImg {
		background: #000;
		border-radius: 50%;
	}
	.gridView.assetManagementDetails.selectedUnitBg .colm2D {
		width: 90px;
	}
	.gridView.assetManagementDetails.selectedUnitBg .colm10D {
		width: auto;
		padding-top: 10px;
	}
	.gridView.assetManagementDetails.selectedUnitBg .colmDi > .divStatusWithIcon {
		margin: 0px;
	}
	.stLocationTable > ul > li:nth-child(2),
	.SWlistUnitsTable > ul > li:nth-child(7),
	.menuLibraryTable > ul > li:nth-child(5),
	.menuLibraryTable > ul > li:nth-child(6),
	.menuLibraryTable > ul > li:nth-child(7),
	.menuLibraryTable > ul > li:nth-child(8),
	.menuLbryMcfTable > ul > li:nth-child(4),
	.menuLbryMcfTable > ul > li:nth-child(5),
	.menuLbryMcfTable > ul > li:nth-child(6),
	.menuLbryMcfTable > ul > li:nth-child(7),
	.navbarLRight.timeZoneSNheader,
	.reportListTable > ul > li:nth-child(1),
	.reportListTable > ul > li:nth-child(2),
	.reportListTable > ul > li:nth-child(4),
	.reportListTable > ul > li:nth-child(5), 
	.HACCPreportListTable > ul > li:nth-child(4),
	.HACCPreportListTable > ul > li:nth-child(5), 
	.reportScdlListTable > ul > li:nth-child(1),
	.reportScdlListTable > ul > li:nth-child(2),
	.reportScdlListTable > ul > li:nth-child(4),
	.reportScdlListTable > ul > li:nth-child(5),
	.reportScdlListTable > ul > li:nth-child(6),
	.selectedUnitHeader > ul > li:nth-child(2),
	.userRoleVETable > ul > li:nth-child(1),
	.userMgmtRolesTable > ul > li:nth-child(1) {
		display: none;
	}
	.reportListTable > ul.tBody > li .reportListBSM,
	.reportListTable > ul.tBody > li .reportListSM,	
	.HACCPreportListTable > ul.tBody > li .reportListSM,
	.reportScdlListTable > ul.tBody > li .reportListBSM,
	.reportScdlListTable > ul.tBody > li .reportListSM{ 
		display:block; 
	}
	.menuLibraryTable > ul > li:nth-child(4) {
		width: auto;
	}
	.userMgmtListTable > ul > li:nth-child(3),
	.userMgmtListTable > ul > li:nth-child(6) {
		padding-right: 0px;
		padding-left: 0px;
	}
	.gridView.selectedUnitBg .colmDi > .titleH2Div {
		font-size: 16px;
	}
	.gridView.selectedUnitBg .colmDi > .titleH4Div {
		padding: 0px 0px 5px;
	} 
	.gridView .colmDi > .deviceSNUTCSM,
	.userMgmtListTable > ul > li .userMListRL {
		display: block;
	}
	.selectedUnitHeader > ul > li:nth-child(1) {
		font-size: 14px;
	}
	.selectedUnitHeader > ul > li:nth-child(1) a {
		margin-right: 15px;
	}
	.menuAStatusTable > ul > li,
	.updateStatusTable > ul > li,
	.mediaStatus > ul > li {
		padding: 5px 10px;
	}
	.addUserForm {
		margin: 0px auto;
	}
	.assigntoBrands > ul > li:nth-child(1) {
		padding-left: 10px;
	}
	.assigntoBrands > ul > li .customCheckbox {
		margin: 10px 10px 5px 0px;
		width: auto;
	}
	.userManagementWrapper .exitingUserRolesSelection {
		padding: 0px 10px 10px;
	}
	.userRoleVETable > ul > li:nth-child(4),
	.userRoleVETable > ul > li:nth-child(6) {
		width: 45px;
	}
	.chooseUserForm > ul {
		padding: 0px 0px 0px;
	}
	.userRoleVETable {
		margin: 15px 0px 0px;
	}
	.chooseUserForm > ul > li .userNameDP > .userDPImg {
		margin: 0px 10px 0px 10px;
	}
	.chooseUserForm > ul > li .userNameDP {
		line-height: 48px;
		font-size: 18px;
	}
	.chooseUserForm > ul > li {
		padding: 0px 0px 0px;
	}
}
@media only screen and (min-width: 320px) and (max-width: 1023px) {
	.subspnDefaultViewOneM{
		min-width: 900px;
	}
	.dashboardAlert{
		width: auto;
		height: auto;
   	 	margin: 15px;
		font-size: 14px; 
	}
	.dashboardAlert .viewBtn{
		margin-left: 0px;
	}
	.dashboardAlert button.close{
		right: 0px;
	}
	.subspnStatusCard li{
		width: 100%;
	} 
	.subspnDashboardGridD .widgetIcon{
		width: 64px;
		height: 64px;
	}
	.subspnDashboardGridD .widgetContent h5,
	.subspnDashboardGridD .widgetContent h4{
		font-size: 16px;
		text-align: right;
	}
	.subspnStatusCard {
		display: flex; 
		overflow: auto; 
		width: 100%;
	} 
	.subspnDefaultViewOneM,
	.subspnDefault,
	.subspnDashboardGrid,
	.manageSubscriptionOuter,
	.invoiceTableOuter,
	.tableSHeader,
	.navBarXScroll {
		display: flex; 
		overflow: auto;
		white-space: nowrap;
		width: 100%;
	}
	.subspnDefaultViewOneM::-webkit-scrollbar,
	.subspnStatusCard::-webkit-scrollbar,
	.subspnDefault::-webkit-scrollbar,
	.subspnDashboardGrid::-webkit-scrollbar,
	.manageSubscriptionOuter::-webkit-scrollbar,
	.invoiceTableOuter::-webkit-scrollbar,
	.tableSHeader::-webkit-scrollbar,
	.navBarXScroll::-webkit-scrollbar {
		display: none;
	}
	.unitRegFormWrapper.alignCenter{
		width:100%;
		float:left;
	} 
	.menuLbryMcfTable > ul > li .btn-danger,
	.menuLibraryTable > ul > li .btn-danger {
		background:#F93251 url("../images/icons/ReloadW.svg") no-repeat center center;
		background-size: 24px auto;
		width:24px;
		height:24px;
		display:inline-block;
		text-indent:-9999px;
	}
	.nav-item.navSearchToggleBox{
		width: auto;
	}
	.headerSearchBox .hSearchBox { 
		min-width: auto;
	}
	.headerSearchBox{
		position:absolute;
		top:60px;
		left:0px; 
		z-index:1;
		width:100%;
	} 
	#wrapper .navbar .sideNavBar {
		display: block;
	}
	#wrapper {
		padding-left: 0px;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper.navActive #page-content-wrapper {
		margin-left: 230px;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper.navActive .navbar .sideNavBar {
		background: url(../images/icons/sideNavBarE.png) no-repeat center center;
		background-size: auto 36px;
	}
	#wrapper #page-content-wrapper {
		margin-left: 0px;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper #sidebar-wrapper {
		width: 0px;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper.navActive .bg-Welbilt.fixed-top {
		left: 230px;
		width: 100%;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper.navActive .sideNavToggleTransparent {
		display: block;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper .bg-Welbilt.fixed-top {
		left: 0px;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper.navActive #sidebar-wrapper {
		width: 230px;
		z-index: 9;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper.navActive {
		overflow: hidden;
	}
	#wrapper.navActive .sidebarNav.bottomNav {
		position: fixed;
	}
	#wrapper .navbar .welbiltRLogo {
		background: url("../images/WBTKitchenConnectWhite.png") no-repeat center center;
		background-size: auto 40px;
		width: 230px;
		border-right: 2px solid #000;
		display: none;
	}
	.sidebarNav > li.brandName .homeNav,
	.sidebarNav > li .dashboardNav,
	.sidebarNav > li .reportNav,
	.sidebarNav > li .alarmsNav,
	.sidebarNav > li .qualityNav,
	.sidebarNav > li .serviceNav,
	.sidebarNav > li .unitNav,
	.sidebarNav > li .recipeNav,
	.sidebarNav > li .menuNav,
	.sidebarNav > li .ListviewA,
	.sidebarNav > li .MapViewA {
		width: 230px;
		height: 64px;
		display: block;
		text-indent: 0px;
		position: relative;
		line-height: 64px;
		padding: 0px 10px 0px 74px;
		background: #000000;
		border-bottom: 1px solid #1f2124;
		border-right: 2px solid #000;
		font-size: 14px;
		color: #afb1b2;
	}
	.sidebarNav > li.brandName .homeNav {
		background: #1f2124;
	}
	.sidebarNav > li .menuNav {
		border-top: 1px solid #1f2124;
		border-bottom: 0px;
	}
	.sidebarNav > li a.active,
	.sidebarNav > li a:hover {
		border-bottom: 1px solid #195d99;
		text-indent: 0px;
		font-size: 14px;
		color: #fff;
		background: none;
	}
	.sidebarNav > li .menuNav.active,
	.sidebarNav > li .menuNav:hover {
		border-top: 1px solid #195d99;
		border-bottom: 0px;
	}
	.sidebarNav > li a.active,
	.sidebarNav > li a:hover {
		border-bottom: 1px solid #195d99;
		text-indent: 0px;
		font-size: 14px;
		color: #fff;
		background: none;
	}
	.sidebarNav > li a.active:before,
	.sidebarNav > li a:hover::before {
		background-color: #195d99;
	}
	.sidebarNav > li.brandName .homeNav:before,
	.sidebarNav > li .dashboardNav:before,
	.sidebarNav > li .reportNav:before,
	.sidebarNav > li .alarmsNav:before,
	.sidebarNav > li .qualityNav:before,
	.sidebarNav > li .serviceNav:before,
	.sidebarNav > li .unitNav:before,
	.sidebarNav > li .recipeNav:before,
	.sidebarNav > li .menuNav:before,
	.sidebarNav > li .ListviewA:before,
	.sidebarNav > li .MapViewA:before {
		position: absolute;
		top: 0px;
		left: 0px;
		content: " ";
		width: 64px;
		height: 64px;
	}
	.sidebarNav > li.brandName .homeNav:before {
		left: 15px;
		width: 265px;
	}
	.sidebarNav > li.brandName .homeNav:before {
		background: #1f2124 url("../images/icons/home.svg") no-repeat left center;
		background-size: auto 36px;
	}
	.sidebarNav > li .dashboardNav:before {
		background: #1f2124 url("../images/icons/dashboard.svg") no-repeat center center;
		background-size: 24px auto;
	}
	.sidebarNav > li .reportNav:before {
		background: #1f2124 url("../images/icons/report.svg") no-repeat center center;
		background-size: 24px auto;
	}
	.sidebarNav > li .alarmsNav:before {
		background: #1f2124 url("../images/icons/home.svg") no-repeat center center;
		background-size: 24px auto;
	}
	.sidebarNav > li .qualityNav:before {
		background: #1f2124 url("../images/icons/quality.svg") no-repeat center center;
		background-size: 24px auto;
	}
	.sidebarNav > li .serviceNav:before {
		background: #1f2124 url("../images/icons/service.svg") no-repeat center center;
		background-size: 24px auto;
	}
	.sidebarNav > li .unitNav:before {
		background: #1f2124 url("../images/icons/unitDetails.svg") no-repeat center center;
		background-size: 24px auto;
	}
	.sidebarNav > li .recipeNav:before {
		background: #1f2124 url("../images/icons/recipeDetails.svg") no-repeat center center;
		background-size: 24px auto;
	}
	.sidebarNav > li .menuNav:before {
		background: #1f2124 url("../images/icons/menu.svg") no-repeat center center;
		background-size: 24px 24px;
	}
	.sidebarNav > li .ListviewA:before {
		background: #1f2124 url("../images/icons/ListviewA.svg") no-repeat center center;
		background-size: 24px auto;
	}
	.sidebarNav > li .MapViewA:before {
		background: #1f2124 url("../images/icons/MapViewA.svg") no-repeat center center;
		background-size: 24px auto;
	}
	.nav-item.navSearchToggleBox { 
        position: inherit; 
        min-width: 0px; 
    }
	#page-content-wrapper.withSearchBar{
		padding-top:125px;
	}
	.navSearchToggled .navbarRight .nav-item{  
		position: static; 
	}
	.navbarRight .navSearchDrpdn { 
		width: 100%;  
		top: 125px;
		left:0px;
		right: 0px; 
		min-width: 100%; 
		max-width:100%;
	}
	.selectedUnitHeader > ul > li:nth-child(1),
	.menuFilterHeader > ul > li:nth-child(1) {
		text-align: center;
		width: 100%;
		float: left;
		padding: 0px 15px;
	}
	.menuFilterHeader > ul > li:nth-child(1) a {
		padding: 10px 12px;
	}
	.selectedUnitHeader > ul > li:nth-child(3),
	.menuFilterHeader > ul > li:nth-child(2) {
		border-top: 1px solid #7e8690;
		text-align: right;
		width: 100%;
		float: left;
		padding: 10px 15px;
	}  
	.frypotLegendSection {
		margin: 5px 0px 10px 10px;
	}
	.frypotLegendSection > li {
		margin: 5px 25px 5px 0;
	}
	.frypotStsLegendSection > li {
		width: 42%;
		text-align: left;
		float: left;
		margin: 5px 10px 5px 0;
	}
	/*** **************  ************** ****
	*** Scroll Tip ***
	**** **************  ************** ***/
	.scroll-tip {
		position: fixed;
		bottom: 10px;
		width: 100%;
		height: 10px;
		display: none;
		left: 0px;
		z-index: 10000;
		background: url("../images/scrollbarEnable.png") no-repeat center;
		background-size: auto 10px;
	}
	.modal-scroll-tip {
		position: fixed;
		bottom: 10px;
		width: 100%;
		height: 10px;
		display: none;
		left: 0px;
		z-index: 10000;
		background: url("../images/scrollbarEnable.png") no-repeat center;
		background-size: auto 10px;
	}
	.chartValLgnd,
	.gridView .colmDiVal > .valH1Div,
	.gridView .colmDiVal > .valH1Div.timeDiv {
		font-size: 32px;
	}
	/*** **************  ************** ****
		*** Login ***
**** **************  ************** ***/
	body.loginBg {
		background: #0d3968 url(../images/Keyvisual.png) no-repeat center center;
		background-size: 100% auto;
	}
	.allLocationsGridLI .locationsGridImg {
		padding: 0px 0px 0px;
	}
	.allUnitsGridLI .unitsGridImg {
		padding: 0px 0px 20px;
	}
	.allLocationsGridLI .locationsGridImg > img {
		max-width: 100px;
		min-width: 100px;
		max-height: 100px;
	}
	.allUnitsGridLI .unitsGridImg > img {
		max-width: 100px;
		max-height: 100px;
	}
	.listUnitsTable > ul > li:nth-child(1),
	.listUnitsTable > ul > li:nth-child(2) {
		width: 60px;
	} 
	.listUnitsTable > ul > li:nth-child(9) {
		width: auto;
	}
	.listUnitsTable > ul > li:nth-child(6) {
		width: 140px;
	}
	.listUnitsTable > ul > li:nth-child(3),
	.listUnitsTable > ul > li:nth-child(4),
	.listUnitsTable > ul > li:nth-child(5), 
	.listUnitsTable > ul > li:nth-child(7) {
		width: auto;
	}
	.haccpUpdateStatusTable > ul > li:nth-child(2),
	.updateStatusTable > ul > li:nth-child(2),
	.mediaStatus > ul > li:nth-child(2) {
		padding-left: 10px;
	}
	.holdingDataTable > ul > li:nth-child(2) {
		padding-left: 10px;
	}
	.holdingDataExpTable > ul > li:nth-child(3),
	.holdingDataExpTable > ul > li:nth-child(4),
	.holdingDataExpTable > ul > li:nth-child(5),
	.holdingDataTable > ul > li:nth-child(3),
	.holdingDataTable > ul > li:nth-child(4),
	.holdingDataTable > ul > li:nth-child(5) {
		width: 130px;
		text-align: center;
	}
	.holdingDataExpTable > ul > li:nth-child(8),
	.holdingDataTable > ul > li:nth-child(8) {
		width: 80px;
		text-align: center;
	}
	.holdingDataExpTable > ul > li:nth-child(1),
	.holdingDataExpTable > ul > li:nth-child(3),
	.holdingDataExpTable > ul > li:nth-child(6),
	.holdingDataTable > ul > li:nth-child(1),
	.holdingDataTable > ul > li:nth-child(3),
	.holdingDataTable > ul > li:nth-child(6),
	.availableUpdatesTable > ul > li:nth-child(3),
	.availableUpdatesTable > ul > li:nth-child(4),
	.availableUpdatesTable > ul > li:nth-child(5),
	.SWavlUpdtsDiv > ul > li:nth-child(2),
	.SWavlUpdtsDiv > ul > li:nth-child(4),
	.SWavlUpdtsDiv > ul > li:nth-child(5),
	.SWavlUpdtsDiv > ul > li:nth-child(6),
	.haccpUpdateStatusTable > ul > li:nth-child(1),
	.haccpUpdateStatusTable > ul > li:nth-child(3),
	.haccpUpdateStatusTable > ul > li:nth-child(4),
	.updateStatusTable > ul > li:nth-child(1),
	.updateStatusTable > ul > li:nth-child(3),
	.updateStatusTable > ul > li:nth-child(4), 
	.menuAStatusTable > ul > li:nth-child(2),
	.menuAStatusTable > ul > li:nth-child(5),
	.menuAStatusTable > ul > li:nth-child(6) {
		display: none;
	}
	.availableUpdatesTable > ul.tBody > li .SWUpdateSM,
	.availableUpdatesTable > ul.tBody > li .SWUpdateSMVD,
	.SWavlUpdtsDiv > ul.tBody > li .SWUpdateSM,
	.SWavlUpdtsDiv > ul.tBody > li .SWUpdateSMVD,
	.haccpUpdateStatusTable > ul.tBody > li .updateStatusSM,
	.updateStatusTable > ul.tBody > li .updateStatusSM, 
	.menuAStatusTable > ul.tBody > li .menuAStatusSM {
		display: block;
		font-size: 12px;
	}
	.availableUpdatesTable > ul > li,
	.SWavlUpdtsDiv > ul > li { 
		padding: 5px 10px;
	}
	.availableUpdatesTable > ul > li:nth-child(2) {
		width: auto;
		padding-left: 0px;
	}
	.availableUpdatesTable > ul > li:nth-child(2) > b {
		display: inline-block;
	}
	.availableUpdatesTable > ul > li:nth-child(2) > br,
	.availableUpdatesTable > ul > li:nth-child(2) > .SWUpdateSM > br {
		display: none;
	}
	.menuUpdateStatusExpTable .menuUpdateExpTable > ul > li {
		padding: 0px 10px;
	}
	.mediaPreparationTable > ul.tHead > li:nth-child(2),
	.mediaScreensaverTable > ul.tHead > li:nth-child(2),
	.mediaCompilationTable > ul.tHead > li:nth-child(2) {
		text-align: center;
	}
	.mediaPreparationTable > ul.tHead > li:nth-child(9),
	.mediaScreensaverTable > ul.tHead > li:nth-child(9),
	.mediaCompilationTable > ul.tHead > li:nth-child(9) {
		text-indent: -9999px;
	}
	.mediaPreparationTable > ul > li:nth-child(9),
	.mediaScreensaverTable > ul > li:nth-child(9),
	.mediaCompilationTable > ul > li:nth-child(9) {
		display:none;
	}
	.amcharts-Button-group {
		display: none !important;
	}
	.notificationTypeForm,
	.myInformationForm {
		padding: 0px 10px;
	}
	.NotifnMgmtTable > ul > li:nth-child(2),
	.NotifnMgmtTable > ul > li:nth-child(4) {
		width: 60px;
	}
	.assignedUnitGroupsTableDiv {
		padding: 10px 10px;
	}
	.assignedUnitGrpsTableOuter,
	.myInformationTable > ul,
	.myInformationTable {
		width: 100%;
		display: block;
	}
	.myInformationTable > ul.tHead > li:nth-child(3),
	.myInformationTable > ul.tBody > li:nth-child(1) {
		display: none;
	}
	.myInformationTable > ul.tHead > li:nth-child(1) {
		width: 74px;
		padding: 0px;
	}
	.myInformationTable > ul.tHead > li:nth-child(2) {
		width: auto;
	}
	.myInformationTable > ul.tBody > li:nth-child(2),
	.myInformationTable > ul.tBody > li:nth-child(3) {
		width: 100%;
		float: left;
		padding: 7px 0px;
	}
	.myInformationTable > ul > li .addUserForm > ul {
		padding: 0px;
	}
	.mediaPreparationTable > ul > li,
	.mediaScreensaverTable > ul > li,
	.mediaCompilationTable > ul > li {
		font-size: 14px;
		word-break: break-word;
	}
}
@media only screen and (min-width: 768px) and (max-width: 834px) {  
	.stConfirmBox .stDataD{
		width:100%;
		float:left;
		text-align:center;
		margin:10px 0px 0px;
	}
	.smartTagsList {
		float: left;
		width:50%;
	} 
	.unitRegForm.floatLeft {
		float: left;
		width:50%;
	}
	.conectivityWrapper {
		width: 96%;
		margin:15px 2% 0px;  
		border-radius:0px;
	} 
	.newReportNS > ul > li:nth-child(1){
		width:185px;
	}
	.newReportWrapperL {
		width: 38%; 
	}
	.newReportWrapperR {
		width: 62%; 
	}
	.subscriptionBForm,
	.newReportFormT > ul > li:nth-child(1),   
	.newReportFormT > ul > li:nth-child(2),
	.datePeriodDiv > ul > li:nth-child(1),
	.datePeriodDiv > ul > li:nth-child(2),
	.reportOptionsList > ul > li{
		width:100%;
		float:left;
	} 
	.billingForm > ul > li.colm50:nth-child(1) {
		width:50%;
		float:left; 
		padding-right:15px; 
	} 
	.billingForm > ul > li.colm50:nth-child(2) {
		width:50%;
		float:left; 
		padding-left:15px; 
	} 
	.rcDeviceWrapper {
		margin: 120px auto 0px;
	}
	.unitQualityPrncTable > ul > li:nth-child(4),
	.unitQualityPrncExpTable > ul > li:nth-child(4),
	.unitQualityPrncTable > ul > li:nth-child(5),
	.unitQualityPrncExpTable > ul > li:nth-child(5) {
		width: 27%;
		padding-left: 0px;
		padding-right: 0px;
	}
	.selectRecipeTable > ul > li:nth-child(3) {
		width: 200px;
	}   
	.selectRecipeTable > ul > li .unitsRCSM,
	.selectRecipeTable > ul > li .unitsRCMD,
	.updateStatusTable > ul.tBody > li .updateStatusSM, 
	.updateStatusTable > ul.tBody > li .updateStatusSB,
	.mediaStatus > ul.tBody > li .updateStatusSM, 
	.mediaStatus > ul.tBody > li .updateStatusSB {
		display: block; 
	}
	.menuLbryLTable > ul > li:nth-child(3) { 
		width:auto;
	} 
	.userMgmtListTable > ul > li:nth-child(6),
	.statusTable > ul > li:nth-child(12),
	.statusTable > ul > li:nth-child(5),
	.statusTable > ul > li:nth-child(9), 
	.statusTable > ul > li:nth-child(10),
	.menuLbryLTable > ul > li:nth-child(6), 
	.menuLbryLTable > ul > li:nth-child(7), 
	.updateStatusTable > ul > li:nth-child(7), 
	.updateStatusTable > ul > li:nth-child(9),
	.mediaStatus > ul > li:nth-child(7), 
	.selectRecipeTable > ul > li:nth-child(4),
	.selectRecipeTable > ul > li:nth-child(6),
	.unitQualityPrncTable > ul > li:nth-child(2),
	.unitQualityPrncExpTable > ul > li:nth-child(2),
	.unitQualityPrncTable > ul > li:nth-child(3),
	.unitQualityPrncExpTable > ul > li:nth-child(3),
	.userMgmtListTable > ul > li:nth-child(1), 
	.userMgmtListTable > ul > li:nth-child(4), 
	.userMgmtListTable > ul > li:nth-child(5) {
		display: none;
	}
	.unitQualityPrncTable > ul > li:nth-child(6),
	.unitQualityPrncExpTable > ul > li:nth-child(6) {
		width: 16%;
	}
	.cookingDataTable > ul > li:nth-child(4),
	.cookingDataTable > ul > li:nth-child(5) {
		width: auto;
		min-width: 120px;
		text-align: center;
	}
	.cookingDataTable > ul > li:nth-child(3) {
		padding: 10px 0px;
		width: auto;
		min-width: 100px;
	}
	/*** **************  ************** ****
	*** Dashboard ***
**** **************  ************** ***/
	.gridView .colm3D {
		float: left;
		width: 50%;
		min-height: 210px;
	}
	.mainMenuGrid > ul > li,
	.allLocationsGrid > ul > li,
	.allUnitsGrid > ul > li {
		width: 33.33%;
	}
	.countryCheckbox > .customCheckbox {
		width: 32%;
	}
	.listLocationsTable > ul > li:nth-child(1),
	.listLocationsTable > ul > li:nth-child(2),
	.listLocationsTable > ul > li:nth-child(8) {
		width: 50px;
	}
	.listLocationsTable > ul > li:nth-child(3),
	.listLocationsTable > ul > li:nth-child(4),
	.listLocationsTable > ul > li:nth-child(5) {
		width: 18%;
	}
	.listLocationsTable > ul > li:nth-child(7) {
		width: 80px;
	}
}
@media only screen and (min-width: 835px) and (max-width: 1023px) {   
	.subspnDashboardGridD .widgetIcon{
		width: 42px;
		height: 42px;
	}
	.subspnDashboardGridD .widgetContent h4{
		font-size: 12px;
	}
	.subspnDashboardGridD .widgetContent h5{
		font-size: 20px;
	}
	.subspnDashboardGrid {
		gap: 10px;
	}
	.subspnDashboardGridD {
		gap: 10px;
		padding: 15px;
	}
	.navHeader > ul > li:nth-child(2),
	.orgznMgmtHeader > ul > li:nth-child(2),
	.orgHeader > ul > li:nth-child(2){
		float:left;
		width:100%;
		max-width:100%;
		text-align:center;
		padding:10px 0px 10px;
		border-top: 1px solid #7e8690;
	}
	.navHeader > ul > li:nth-child(1), 
	.orgznMgmtHeader > ul > li:nth-child(1), 
	.orgHeader > ul > li:nth-child(1){
		float:left;
		width:100%;
	} 
	.bakingPlansList > ul > li:nth-child(3), 
	.bakingPlansList > ul > li:nth-child(4) {
		width: 220px;
	}
	.stConfirmBox .stDataD{
		width:100%;
		float:left;
		text-align:center;
		margin:10px 0px 0px;
	}
	.smartTagsList {
		float: left;
		width:55%;
	} 
	.unitRegForm.floatLeft {
		float: left;
		width:45%;
	} 
	.reportHeader > ul > li:nth-child(1) > a{
		float:left;
		width:50%;
		text-align:center;
		margin:0px;
		padding:10px 0px;
	}  
	.reportHeader > ul > li:nth-child(1){
		float:left;
		width:100%;
	} 
	.reportHeader > ul > li:nth-child(2){
		float:left;
		width:100%;
		text-align:center;
		padding:10px 0px 10px;
		border-top: 1px solid #7e8690;
	}
	.reportHeader > ul > li .checkBoxListGrp {
		float:left;
		width:100%;
		text-align:center;
		padding:0px 0px 10px;
		border-bottom: 1px solid #7e8690;
	}	
	.newReportWrapper div.react-datepicker-popper[data-placement^=bottom],	
	.newReportWrapper .react-datepicker-popper{
		right: 0px;
		left: inherit !important;
	}
	.newReportForm .endDatePicker div.react-datepicker-popper[data-placement^=bottom],
	.newReportForm .endDatePicker .react-datepicker-popper{ 
		left: -100px !important;
	}
	.newReportNS > ul > li:nth-child(1){
		width:185px;
	}
	.newReportWrapperL {
		width: 45%; 
	}
	.newReportWrapperR {
		width: 55%; 
	}
	.newReportForm .endDatePicker, 
	.newReportForm .input-group.timeSelection, 
	.newReportForm .input-group.startDateSelection {
		width: 180px;
	}
	.newReportFormT > ul > li:nth-child(1),   
	.newReportFormT > ul > li:nth-child(2),
	.datePeriodDiv > ul > li:nth-child(1),
	.datePeriodDiv > ul > li:nth-child(2),
	.reportOptionsList > ul > li{
		width:100%;
		float:left;
	}
	.mediaStatus > ul.tBody > li .updateStatusSM,
	.updateStatusTable > ul.tBody > li .updateStatusSM, 
	.updateStatusTable > ul.tBody > li .updateStatusSB, 
	.unitRegListTable > ul.tBody > li .unitsListAN,
	.unitRegCList > ul.tBody > li .unitsListAN,
	.SWlistUnitsTable > ul.tBody > li .unitsListAN,
	.SWuStatusTable > ul.tBody > li .SWuStatusSM,
	.SWuStatusTable > ul.tBody > li .SWuStatusAN,
	.orgUnitsList > ul.tBody > li .unitsListAN,
	.orgUnitsList > ul.tBody > li .unitsListSM, 
	.orgUnitsList > ul.tBody > li .unitsListSML, 
	.unitRegListTable > ul.tBody > li .unitsListAN, 
	.unitRegCList > ul.tBody > li .unitsListAN {
		display: block;
	}  
	.orgTable > ul > li:nth-child(5) {
		width: 110px;
	} 
	.SWuStatusTable > ul > li:nth-child(6) {
		width: 140px;
	} 
	.orgUnitsList > ul > li:nth-child(3),
	.orgTable > ul > li:nth-child(3){
		padding-left:0px;
	}  
	.mediaStatus > ul > li:nth-child(3),
	.mediaStatus > ul > li:nth-child(5),
	.bakingPlansList > ul > li:nth-child(2),
	.mediaAllTable > ul > li:nth-child(5),
	.mediaAllTable > ul > li:nth-child(6),
	.mediaAllTable > ul > li:nth-child(7),
	.menuLbryLTable > ul > li:nth-child(6), 
	.menuLbryLTable > ul > li:nth-child(7), 
	.menuUpdateStatusExpTable > ul.tBody > li:nth-child(1),
	.updateStatusTable > ul > li:nth-child(7), 
	.updateStatusTable > ul > li:nth-child(9),  
	.menuLibraryTable > ul > li:nth-child(7),
	.menuLibraryTable > ul > li:nth-child(8), 
	.menuLbryMcfTable > ul > li:nth-child(6),
	.menuLbryMcfTable > ul > li:nth-child(7),
	.unitQualityPrncTable > ul > li:nth-child(3),
	.unitQualityPrncExpTable > ul > li:nth-child(3),
	.userMgmtListTable > ul > li:nth-child(1), 
	.userMgmtListTable > ul > li:nth-child(4), 
	.userMgmtListTable > ul > li:nth-child(5),
	.menuAStatusTable > ul > li:nth-child(8),
	.SWlistUnitsTable > ul > li:nth-child(7),
	.SWuStatusTable > ul > li:nth-child(4),
	.SWuStatusTable > ul > li:nth-child(5),
	.SWuStatusTable > ul > li:nth-child(7),
	.SWuStatusTable > ul > li:nth-child(14),
	.SWuStatusTable > ul > li:nth-child(11), 
	.allErrorListTable > ul > li:nth-child(2),
	.allErrorListTable > ul > li:nth-child(5),
	.allErrorListTable > ul > li:nth-child(10),
	.allErrorListTable > ul > li:nth-child(11),
	.orgUnitsList > ul > li:nth-child(4),
	.orgUnitsList > ul > li:nth-child(5),
	.orgUnitsList > ul > li:nth-child(6),
	.orgTable > ul > li:nth-child(6),
	.unitRegListTable > ul > li:nth-child(5),
	.unitRegCList > ul > li:nth-child(5),
	.STComfmtList > ul > li:nth-child(4), 
	.STComfmtList > ul > li:nth-child(5),
	.statusTable > ul > li:nth-child(12),
	.statusTable > ul > li:nth-child(9), 
	.statusTable > ul > li:nth-child(10),
	.mediaPreparationTable > ul > li:nth-child(1),
	.mediaPreparationTable > ul > li:nth-child(4),
	.mediaPreparationTable > ul > li:nth-child(5),
	.mediaPreparationTable > ul > li:nth-child(8),
	.mediaScreensaverTable > ul > li:nth-child(1),
	.mediaScreensaverTable > ul > li:nth-child(4),
	.mediaScreensaverTable > ul > li:nth-child(5),
	.mediaScreensaverTable > ul > li:nth-child(8),
	.mediaCompilationTable > ul > li:nth-child(1),
	.mediaCompilationTable > ul > li:nth-child(4),
	.mediaCompilationTable > ul > li:nth-child(8),
	.SWlistUnitsTable > ul > li:nth-child(1),
	.SWlistUnitsTable > ul > li:nth-child(4),
	.SWlistUnitsTable > ul > li:nth-child(5),
	.reportAsgnList > ul > li:nth-child(1),
	.reportAsgnList > ul > li:nth-child(4),
	.reportAsgnList > ul > li:nth-child(5),
	.unitRegListTable > ul > li:nth-child(1),
	.unitRegListTable > ul > li:nth-child(3),
	.unitRegListTable > ul > li:nth-child(4),
	.unitRegCList > ul > li:nth-child(1),
	.unitRegCList > ul > li:nth-child(3),
	.unitRegCList > ul > li:nth-child(4),
	.listUnitsTable > ul > li:nth-child(4),
	.listUnitsTable > ul > li:nth-child(5),
	.listLocationsTable > ul > li:nth-child(1) {
		display: none;
	}
	.conectivityWrapper {
		width: 96%;
		margin:15px 2% 0px;  
		border-radius:0px;
	} 
	.billingOForm > ul > li:nth-child(1){
		width:55%;
	}
	.billingFormWrapper > ul > li:nth-child(1),
	.billingFormWrapper > ul > li, 
	.billingPlanForm > ul > li.colm50,
	.billingPlanForm > ul > li.colm33,
	.billingPlanForm > ul > li{
		float:left;
		width:100%;
	}
	.billingPlanForm > ul > li:nth-child(2),
	.billingPlanForm > ul > li:nth-child(3){
		padding-left:0px;
	} 
	.subscriptionBForm {
		width:100%;
		float:left; 
	}
	.billingForm > ul > li.colm50:nth-child(1) {
		width:50%;
		float:left; 
		padding-right:15px; 
	} 
	.billingForm > ul > li.colm50:nth-child(2) {
		width:50%;
		float:left; 
		padding-left:15px; 
	} 
	.menuUpdateStatusExpTable > ul > li:nth-child(2) {
		padding-left: 10px;
	}
	.menuLibraryTable > ul > li:nth-child(5), 
	.menuLibraryTable > ul > li:nth-child(6),
	.menuLbryMcfTable > ul > li:nth-child(5) {
		width: 140px;
	}
	.menuLbryMcfTable > ul > li:nth-child(9),
	.menuLibraryTable > ul > li:nth-child(9),
	.menuLibraryTable > ul > li:nth-child(10) {
		width: 80px; 
	}
	.menuLbryLTable > ul > li:nth-child(3) { 
		width:auto;
	} 
	.unitQualityPrncTable > ul > li:nth-child(2),
	.unitQualityPrncExpTable > ul > li:nth-child(2),
	.unitQualityPrncTable > ul > li:nth-child(5),
	.unitQualityPrncExpTable > ul > li:nth-child(5) {
		width: 20%;
	}
	.unitQualityPrncTable > ul > li:nth-child(4),
	.unitQualityPrncExpTable > ul > li:nth-child(4) {
		width: 24%;
	}
	.gridViewTable.gridViewAMcolm6DSM .gridTableView > ul > li:first-child {
		width: 80px;
	}
	.gridViewTable.gridViewAMcolm6DSM .gridTableView.gridTableViewSW > ul > li:first-child {
		width: 100%;
	}
	.utilizationExpTable > ul > li:nth-child(3),
	.utilizationExpTable > ul > li:nth-child(4),
	.utilizationExpTable > ul > li:nth-child(5),
	.utilizationTable > ul > li:nth-child(3),
	.utilizationTable > ul > li:nth-child(4),
	.utilizationTable > ul > li:nth-child(5) {
		width: 130px;
		text-align: center;
	}
	.gridViewSMcolm39DSM .gridView .colm3D {
		width: 35%;
		float: inherit;
		min-height: auto;
	}
	.gridViewSMcolm39DSM .gridView .colm9D {
		width: 65%;
		float: inherit;
		min-height: auto;
	}
	.gridViewcolm4DSM .gridView .colm4D {
		float: left;
		width: 50%;
		min-height: 190px;
	}
	.gridViewTable.gridViewcolm4DSM > .gridView,
	.gridViewTable.gridViewcolm4DSM {
		width: inherit;
		display: initial;
		padding: 0px;
		float: inherit;
	}
	.gridViewTable.gridViewcolm4DSM:first-child > .gridView .colm4D.colm4D1 {
		border-left: 0px;
	}
	.gridViewTable.gridViewcolm4DSM > .gridView .colm4D.colm4D1 {
		border-left: 1px solid #000;
	}
	.gridViewTable.gridViewcolm4DSM:first-child > .gridView .colm4D:nth-child(2) {
		border-right: 0px;
	}
	.gridViewTable.gridViewcolm4DSM .gridView .colm4D.colm4D3 {
		border-right: 1px solid #000;
	} 
	.gridView .colmDiVal > .valH1Div {
		font-size: 32px;
	}
	.gridView .colm3D {
		float: left;
		width: 50%;
		min-height: 210px;
	}
	.mainMenuGrid > ul > li,
	.allLocationsGrid > ul > li,
	.allUnitsGrid > ul > li {
		width: 25%;
	}
	.menuLibraryTable > ul.tBody > li .menuSListRS,
	.menuLbryMcfTable > ul.tBody > li .menuSListRS,
	.mediaPreparationTable > ul.tBody > li .mediaSListSM,
	.mediaPreparationTable > ul.tBody > li .mediaSListAS,
	.mediaScreensaverTable > ul.tBody > li .mediaSListSM,
	.mediaScreensaverTable > ul.tBody > li .mediaSListAS,
	.mediaCompilationTable > ul.tBody > li .mediaSListSM,
	.mediaCompilationTable > ul.tBody > li .mediaSListAS,
	.SWlistUnitsTable > ul.tBody > li .unitsListSM,
	.reportAsgnList > ul.tBody > li .unitsListSM,
	.unitRegListTable > ul.tBody > li .unitsListSM,
	.unitRegCList > ul.tBody > li .unitsListSM,
	.listUnitsTable > ul > li .unitsListSM,
	.userMgmtListTable > ul > li .userMListRL {
		display: block;
		font-size: 12px;
	}
	.listUnitsTable > ul > li:nth-child(2),
	.listLocationsTable > ul > li:nth-child(2),
	.listLocationsTable > ul > li:nth-child(7) {
		padding: 7px 0px;
	}
	.listUnitsTable > ul.tBody > li:nth-child(2) > img .listLocationsTable > ul.tBody > li:nth-child(2) > img {
		width: 36px;
	}
	.listUnitsTable > ul > li:nth-child(3) {
		padding-left: 0px;
	}
	.countryCheckbox > .customCheckbox {
		width: 32%;
	}
	.unitRegListTable > ul > li:nth-child(2),
	.unitRegCList > ul > li:nth-child(2) {
		padding-left: 10px;
	}
}
@media only screen and (min-width: 1024px) and (max-width: 1220px) { 
	.tableSHeader > ul > li:nth-child(1),
	.tableSHeader > ul > li:nth-child(2),
	.tableSHeader > ul > li:nth-child(3) { 
		width: 260px;
	}  
	.tableSHeader > ul > li .form-control{
		width: 100%;
	}
	.stLocationTable > ul > li:nth-child(2) p { 
		width:360px;  
	}
	.mediaStatus > ul > li:nth-child(3), 
	.mediaStatus > ul > li:nth-child(4), 
	.mediaStatus > ul > li:nth-child(5) {
		width: 180px;
	}
	.bakingPlansList > ul > li:nth-child(2), 
	.bakingPlansList > ul > li:nth-child(3) {
		width: 200px;
	}  
	.bakingPlansList > ul > li:nth-child(4) {
		width: 220px;
	}
	.mediaStatus > ul > li:nth-child(7),
	.bakingPlansList > ul.tHead > li:nth-child(5) {
		width: 130px; 
	}
	.bakingPlansList > ul > li:nth-child(5) {
		width: 118px; 
	}
	.allErrorListTable.allHErrorListTable > ul > li:nth-child(6){
		width:160px;
	}
	.userMgmtListTable > ul.tBody > li:nth-child(6){
		font-size:14px;
	}  
	.menuLibraryTable > ul > li:nth-child(9),
	.menuLibraryTable > ul > li:nth-child(10) {
		width: 110px;
		padding-left:5px;
		padding-right:5px;
	}
	.menuLibraryTable > ul > li:nth-child(4) {
		width: 340px;
		padding-right: 20px;
	} 
	.menuLibraryTable > ul.tBody > li .menuSListRS,
	.menuLbryMcfTable > ul.tBody > li .menuSListRS {
		display: block;
		font-size: 14px;
	}
	.SWlistUnitsTable > ul.tBody > li .unitsListSM,
	.SWuStatusTable > ul.tBody > li .SWuStatusSM,
	.orgUnitsList > ul.tBody > li .unitsListAN,
	.orgUnitsList > ul.tBody > li .unitsListSM, 
	.orgUnitsList > ul.tBody > li .unitsListSML{
		display: block;
	}  
	.orgTable > ul > li:nth-child(5) {
		width: 110px;
	} 
	.orgUnitsList > ul > li:nth-child(3),
	.orgTable > ul > li:nth-child(3){
		padding-left:0px;
	}   
	.STComfmtList > ul > li:nth-child(7),
	.SWlistUnitsTable > ul > li:nth-child(4),
	.SWlistUnitsTable > ul > li:nth-child(5),
	.SWuStatusTable > ul > li:nth-child(4),
	.SWuStatusTable > ul > li:nth-child(5),
	.SWuStatusTable > ul > li:nth-child(14), 
	.mediaPreparationTable > ul > li:nth-child(4),
	.mediaScreensaverTable > ul > li:nth-child(4),
	.mediaCompilationTable > ul > li:nth-child(4),
	.unitRegListTable > ul > li:nth-child(3),
	.unitRegListTable > ul > li:nth-child(4),
	.unitRegCList > ul > li:nth-child(3),
	.unitRegCList > ul > li:nth-child(4),
	.holdingDataExpTable > ul > li:nth-child(1),
	.holdingDataExpTable > ul > li:nth-child(3),
	.holdingDataTable > ul > li:nth-child(1),
	.holdingDataTable > ul > li:nth-child(3),
	.haccpUpdateStatusTable > ul > li:nth-child(3),
	.haccpUpdateStatusTable > ul > li:nth-child(4),
	.updateStatusTable > ul > li:nth-child(3), 
	.menuAStatusTable > ul > li:nth-child(5), 
	.allErrorListTable.allHErrorListTable > ul > li:nth-child(10),
	.userMgmtListTable > ul > li:nth-child(4),
	.userMgmtListTable > ul > li:nth-child(5),
	.statusTable > ul > li:nth-child(9), 
	.statusTable > ul > li:nth-child(10),
	.orgUnitsList > ul > li:nth-child(4),
	.orgUnitsList > ul > li:nth-child(5),
	.orgUnitsList > ul > li:nth-child(6),
	.orgTable > ul > li:nth-child(6), 
	.menuLibraryTable > ul > li:nth-child(7),
	.menuLibraryTable > ul > li:nth-child(8),
	.menuLbryMcfTable > ul > li:nth-child(6),
	.menuLbryMcfTable > ul > li:nth-child(7),
	.unitQualityPrncTable > ul > li:nth-child(3),
	.unitQualityPrncExpTable > ul > li:nth-child(3) {
		display: none;
	}
	.unitQualityPrncTable > ul > li:nth-child(2),
	.unitQualityPrncExpTable > ul > li:nth-child(2),
	.unitQualityPrncTable > ul > li:nth-child(5),
	.unitQualityPrncExpTable > ul > li:nth-child(5) {
		width: 20%;
	}
	.unitQualityPrncTable > ul > li:nth-child(4),
	.unitQualityPrncExpTable > ul > li:nth-child(4) {
		width: 24%;
	}
	.gridView .colmDi>h4 .dropdown>.dropdown-toggle {
		padding-left: 0px;
	}
	.TPMValueTable > ul > li:nth-child(1) {
		display: none;
	}
	.TPMValueTable > ul > li {
		padding-left: 2px;
		padding-right: 2px;
	}
	.TPMValueTable > ul > li:nth-child(2) {
		padding-left: 0px;
		width: auto;
	}
	.TPMValueTable > ul > li:nth-child(3),
	.TPMValueTable > ul > li:nth-child(4),
	.TPMValueTable > ul > li:nth-child(5),
	.TPMValueTable > ul > li:nth-child(6),
	.TPMValueTable > ul > li:nth-child(7),
	.TPMValueTable > ul > li:nth-child(8),
	.TPMValueTable > ul > li:nth-child(9),
	.TPMValueTable > ul > li:nth-child(10),
	.TPMValueTable > ul > li:nth-child(11),
	.TPMValueTable > ul > li:nth-child(12),
	.TPMValueTable > ul > li:nth-child(13),
	.TPMValueTable > ul > li:nth-child(14) {
		min-width: 65px;
		text-align: center;
		font-size: 12px;
	}
	.disposeInformationTable > ul > li:nth-child(4) {
		width: 60px;
		padding-left: 0px;
		padding-right: 0px;
	}
	.disposeInformationTable > ul > li:nth-child(5),
	.disposeInformationTable > ul > li:nth-child(6),
	.disposeInformationTable > ul > li:nth-child(7) {
		width: 110px;
	}
	.navbarLeft > li .navStoreText {
		font-size: 14px;
	}
	.navbarLeft > li .navStoreText > b {
		font-size: 12px;
	}
	.gridViewSMcolm39DSM .gridView .colm3D {
		width: 35%;
		float: inherit;
		min-height: auto;
	}
	.gridViewSMcolm39DSM .gridView .colm9D {
		width: 65%;
		float: inherit;
		min-height: auto;
	}
	.gridViewTable.gridViewAMcolm6DSM .gridView .gridTableView > ul > li > ul > li > label {
		width: 190px;
	}
	.gridViewTable.gridViewAMcolm6DSM .gridTableView > ul > li:first-child {
		width: 80px;
	}
	.gridViewTable.gridViewAMcolm6DSM .gridTableView.gridTableViewSW > ul > li:first-child {
		width: 100%;
	}
	.holdingDataExpTable > ul > li:nth-child(3),
	.holdingDataExpTable > ul > li:nth-child(4),
	.holdingDataExpTable > ul > li:nth-child(5),
	.holdingDataTable > ul > li:nth-child(3),
	.holdingDataTable > ul > li:nth-child(4),
	.holdingDataTable > ul > li:nth-child(5),
	.utilizationExpTable > ul > li:nth-child(3),
	.utilizationExpTable > ul > li:nth-child(4),
	.utilizationExpTable > ul > li:nth-child(5),
	.utilizationTable > ul > li:nth-child(3),
	.utilizationTable > ul > li:nth-child(4),
	.utilizationTable > ul > li:nth-child(5) {
		width: 130px;
		text-align: center;
	}
	.allLocationsGridLI .locationsGridImg {
		padding: 0px 0px 0px;
	}
	.allUnitsGridLI .unitsGridImg {
		padding: 0px 0px 20px;
	}
	.allLocationsGridLI .locationsGridImg > img {
		max-width: 100px;
		min-width: 100px;
		max-height: 100px;
	}
	.allUnitsGridLI .unitsGridImg > img {
		max-width: 100px;
		max-height: 100px;
	}
	.gridView .colmDiVal > .valH1Div.timeDiv,
	.gridView .colmDiVal > .valH1Div {
		font-size: 30px;
	}
	.mainMenuGrid > ul > li,
	.allLocationsGrid > ul > li,
	.allUnitsGrid > ul > li {
		width: 20%;
	}
	.errorsStatusTable > div > div {
		padding: 2px 5px 10px 0px;
	}
	.errorsStatusTable > div > div:nth-child(1) {
		font-size: 24px;
	}
	.gridView .colmDiVal > .dashboardWidget.valH1WStatusDiv.noErrorStatus {
		background: url(../images/icons/smileyGood.svg) no-repeat left center;
		background-size: 60px auto;
	}
	.gridView .colmDiVal > .dashboardWidget.valH1WStatusDiv {
		padding: 20px 0px 20px 65px;
		font-size: 24px;
		width: 90%;
	}
	.holdingDataTable > ul > li:nth-child(2) {
		padding-left: 10px;
	} 
	.holdingDataExpTable > ul > li:nth-child(8),
	.holdingDataTable > ul > li:nth-child(8) {
		width: 80px;
		text-align: center;
	} 
	.userMgmtListTable > ul > li .userMListRL,
	.haccpUpdateStatusTable > ul.tBody > li .updateStatusSM,
	.updateStatusTable > ul.tBody > li .updateStatusSM, 
	.menuAStatusTable > ul.tBody > li .menuAStatusSM {
		display: block;
	} 
	.unitRegListTable > ul.tBody > li .unitsListSM,
	.unitRegCList > ul.tBody > li .unitsListSM,
	.mediaPreparationTable > ul.tBody > li .mediaSListSM,
	.mediaScreensaverTable > ul.tBody > li .mediaSListSM,
	.mediaCompilationTable > ul.tBody > li .mediaSListSM {
		display: block;
		font-size: 12px;
	}
}
@media only screen and (min-width: 835px) and (max-width: 1220px) {  
	.navbarLRight > li .deviceSNText, 
	.navbarLRight > li .UTCText{
		line-height:16px;
	}
	.navbarLeft > li .navStoreIcon { 
		width: 22px;
		line-height: 22px; 
	}
	.navbarLeft > li .navStoreIcon > img {
		max-width: 22px;
		max-height: 22px;
	}
	.navbarLeft.fivePillarsHeader > li .navStoreText {
		width: 154px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 14px;
	}
	.navbarLeft.fivePillarsHeader > li .navStoreText > b {
		font-size: 12px; 
		display: inline;
	}
	.navbarLeft.fivePillarsHeader > li {
		width: auto;
		word-break: break-word;
		padding: 5px 10px 4px;
	}
	.navbar-dark .navbarLRight.timeZoneSNheader a {
		padding-right: 10px;
	}
	.navbarLeft.fivePillarsHeader > li:first-child {
		border-right: 0px;
	}
	.gridView .colmDiVal > .valH1Div.maxChar12 { 
		font-size: 34px; 
	}
	.gridView .colmDiVal > .valH1Div.maxChar14 { 
		font-size: 32px; 
	}
	.serviceManagementWsearch .nav-item.navSearchToggleBox {
		width: 350px;
	}
	.serviceManagementWsearch .navbarLRight.timeZoneSNheader,
	.errorListTable > ul > li:nth-child(2),
	.errorListTable > ul > li:nth-child(4),
	.errorListTable > ul > li:nth-child(6), 
	.fErrorListTable > ul > li:nth-child(2),
	.fErrorListTable > ul > li:nth-child(4),
	.fErrorListTable > ul > li:nth-child(5), 
	.fErrorListTable > ul > li:nth-child(7),
	.errorListTable.historicErrorsTable > ul > li:nth-child(7),
	.errorListTable.historicErrorsTable > ul > li:nth-child(8),  
	.fErrorListTable.historicErrorsTable > ul > li:nth-child(8),
	.fErrorListTable.historicErrorsTable > ul > li:nth-child(9) {
		display: none;
	} 
	.fErrorListTable > ul.tBody > li .errorTypeSM,
	.errorListTable > ul.tBody > li .errorTypeSM {
		display: inline-block;
	}
	.fErrorListTable > ul.tBody > li .errorListSM,
	.errorListTable > ul.tBody > li .errorListSM {
		display: block;
		font-size: 12px;
	}
}
@media only screen and (min-width: 1024px) and (max-width: 1380px) {
	.subspnStatusCard li{
		width: 49%;
	}
	.subspnDashboardGridD .widgetIcon{
		width: 48px;
		height: 48px;
	}
	.subspnDashboardGridD .widgetContent h4{
		font-size: 14px;
	}
	.subspnDashboardGridD .widgetContent h5{
		font-size: 24px;
	}
	.subspnDashboardGrid {
		gap: 20px;
	}
	.subspnDashboardGridD {
		gap: 10px;
		padding: 15px;
	}
	.gridView .colmDiVal > .valH1Div.maxChar12 { 
		font-size: 34px; 
	}
	.gridView .colmDiVal > .valH1Div.maxChar14 { 
		font-size: 32px; 
	} 
	.headerSearchBox .hSearchBox{
		min-width: 215px;
	}
	.nav-item.navSearchToggleBox {
		position: relative;
		width: 385px;
	}
}
@media only screen and (min-width: 880px) and (max-width: 1025px) {
	.brandDropdown .dropdown {
		position: absolute;
		right: 0px;
		top: 5px;
	}
	.brandDropdown .dropdown.datepickerBtnGroup {
		position: absolute;
		right: 12px;
		top: 20px;
	}
	.gridView .colmDi.brandDropdown > h4 .dropdown > .dropdown-toggle {
		max-width: 130px;
	}
}
@media only screen and (min-width: 1080px) and (max-width: 1320px) { 
	.disposeInformationTable > ul > li:nth-child(4),
	.disposeInformationTable > ul > li:nth-child(5),
	.disposeInformationTable > ul > li:nth-child(6) {
		width: 165px;
		text-align: center;
	}
	.disposeInformationTable > ul > li:nth-child(7){
		width:180px;
	}
	.errorListTable > ul > li p{
		width:240px;
	}
	.fErrorListTable.historicErrorsTable > ul > li:nth-child(6) p { 
		max-width:180px;
	}
	.selectedUnitHeader > ul > li .checkBoxListGrp .customCheckbox label{
		margin-right:5px;
	}
	.brandDropdown .dropdown {
		position: absolute;
		right: 0px;
		top: 5px;
	}
	.brandDropdown .dropdown.datepickerBtnGroup {
		position: absolute;
		right: 12px;
		top: 20px;
	}
	.gridView .colmDi.brandDropdown > h4 .dropdown > .dropdown-toggle {
		max-width: 180px;
	} 
}
@media only screen and (min-width: 1080px) and (max-width: 1920px) {
	.newReportWrapper{
		width: 1000px;
		float: none;
		padding: 25px 0px;
		margin: 0px auto;
		clear: both;
		display:table;
	}
	.formWrapper{
		width: 1000px;
		float: none; 
		margin:15px auto 0px;
		clear: both;
		display:table;
	}
}
@media only screen and (min-width: 1221px) and (max-width: 1319px) {  
	.subspnDashboardGridD .widgetIcon{
		width: 48px;
		height: 48px;
	}
	.subspnDashboardGridD .widgetContent h4{
		font-size: 16px;
	}
	.subspnDashboardGridD .widgetContent h5{
		font-size: 24px;
	}
	.subspnDashboardGrid {
		gap: 20px;
	}
	.subspnDashboardGridD {
		gap: 10px;
		padding: 15px;
	}
	.stLocationTable > ul > li:nth-child(2) p { 
		width:420px;  
	} 
	.mediaStatus > ul > li:nth-child(3) {
		width: 260px;
	} 
	.mediaStatus > ul > li:nth-child(4), 
	.mediaStatus > ul > li:nth-child(5) {
		width: 240px;
	} 
	.mediaPreparationTable > ul > li:nth-child(6),
	.mediaScreensaverTable > ul > li:nth-child(6),
	.mediaCompilationTable > ul > li:nth-child(6) {
		width: 110px; 
		padding-left:5px;
		padding-right: 5px;
		text-align: center;
	}
	.SWuStatusTable > ul > li:nth-child(6), 
	.SWuStatusTable > ul > li:nth-child(7) {
		width: 140px; 
	} 
	.unitRegListTable > ul > li:nth-child(5), 
	.unitRegListTable > ul > li:nth-child(6) {
		width: 165px; 
	}
	.userMgmtListTable > ul > li:nth-child(8){
		width: 80px;
	}
	.userMgmtListTable > ul > li:nth-child(7){
		width: 110px;
	}
	.statusTable > ul > li:nth-child(12),
	.statusTable > ul > li:nth-child(5) {
		width:100px;
	}
	.SWuStatusTable > ul > li:nth-child(12), 
	.ntnLibraryTable > ul > li:nth-child(6){
		width:235px;
	} 
	.SWuStatusTable > ul.tBody > li .SWuStatusSM, 
	.ntnLibraryTable > ul.tBody > li .unitsListSM{
		display:block;
	}
	.statusTable > ul > li:nth-child(3),   
	.ntnLibraryTable > ul > li:nth-child(9){
		width:160px;
	}
	.ntnLibraryTable > ul.tHead > li:nth-child(10){
		width:132px;
	}
	.statusTable > ul > li:nth-child(7),
	.ntnLibraryTable > ul > li:nth-child(8),
	.ntnLibraryTable > ul > li:nth-child(10) {
		width: 120px; 
	} 
	.updateStatusTable > ul > li:nth-child(8) {
		width: 90px; 
	}   
	.SWuStatusTable > ul > li:nth-child(4),
	.SWuStatusTable > ul > li:nth-child(5), 
	.SWuStatusTable > ul > li:nth-child(14), 
	.mediaPreparationTable > ul > li:nth-child(5),
	.mediaScreensaverTable > ul > li:nth-child(5),
	.mediaCompilationTable > ul > li:nth-child(5), 
	.ntnLibraryTable > ul > li:nth-child(3),
	.ntnLibraryTable > ul > li:nth-child(4),  
	.statusTable > ul > li:nth-child(4),
	.statusTable > ul > li:nth-child(5),
	.statusTable > ul > li:nth-child(8),  
	.allErrorListTable.allHErrorListTable > ul > li:nth-child(10){
		display:none;
	}
	 .navbarLeft.fivePillarsHeader > li .navStoreText {
		width: 140px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 14px;
	}
	.navbarLRight > li .deviceSNText, 
	.navbarLRight > li .UTCText{
		line-height:16px;
	}
}
@media only screen and (min-width: 1320px) and (max-width: 1380px) {    
	.stLocationTable > ul > li:nth-child(2) p { 
		width:450px;  
	} 
	.mediaStatus > ul > li:nth-child(3) {
		width: 280px;
	} 
	.mediaStatus > ul > li:nth-child(4), 
	.mediaStatus > ul > li:nth-child(5) {
		width: 260px;
	}
	.navbarLeft.fivePillarsHeader > li .navStoreText {
		width: 140px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 14px;
	}
	.navbarLRight > li .deviceSNText, 
	.navbarLRight > li .UTCText{
		line-height:16px;
	}
}
@media only screen and (min-width: 1380px) and (max-width: 1600px) {  
	.subspnStatusCard li{
		width: 49%;
	}
	.subspnDashboardGridD .widgetIcon{
		width: 48px;
		height: 48px;
	}
	.subspnDashboardGridD .widgetContent h4{
		font-size: 16px;
	}
	.subspnDashboardGridD .widgetContent h5{
		font-size: 26px;
	}
	.subspnDashboardGrid {
		gap: 20px;
	}
	.subspnDashboardGridD {
		gap: 10px;
		padding: 15px;
	}
	.perMonthWidget .dayList{
		gap: 8px;
	}
	.brandsList > ul > li {
		width: 256px; 
	} 
	.stLocationTable > ul > li:nth-child(2) p { 
		width:500px;  
	} 
	.gridView .colmDiVal > .valH1Div.maxChar12 { 
		font-size: 34px; 
	}
	.gridView .colmDiVal > .valH1Div.maxChar14 { 
		font-size: 32px; 
	}
	.menuLbryMcfTable > ul > li:nth-child(5),
	.mediaStatus > ul > li:nth-child(3) {
		width: 280px;
	} 
	.mediaStatus > ul > li:nth-child(4), 
	.mediaStatus > ul > li:nth-child(5),
	.bakingPlansList > ul > li:nth-child(2), 
	.bakingPlansList > ul > li:nth-child(3),
	.bakingPlansList > ul > li:nth-child(4) {
		width: 260px;
	}
	.menuAStatusTable > ul > li:nth-child(8),
	.updateStatusTable > ul > li:nth-child(3),
	.updateStatusTable > ul > li:nth-child(4),
	.updateStatusTable > ul > li:nth-child(5),
	.updateStatusTable > ul > li:nth-child(6),
	.mediaStatus > ul > li:nth-child(3),
	.mediaStatus > ul > li:nth-child(4),
	.mediaStatus > ul > li:nth-child(5) {
		width: 220px;
	} 
	.unitRegListTable > ul > li:nth-child(5),
	.unitRegListTable > ul > li:nth-child(6),
	.unitRegCList > ul > li:nth-child(5),
	.unitRegCList > ul > li:nth-child(6) {
		width:190px;
	} 
	.unitRegListTable > ul > li:nth-child(7),
	.unitRegCList > ul > li:nth-child(7),
	.menuAStatusTable > ul > li:nth-child(5),
	.menuAStatusTable > ul > li:nth-child(6),
	.menuAStatusTable > ul > li:nth-child(7),
	.statusTable > ul > li:nth-child(5),
	.statusTable > ul > li:nth-child(8){
		width: 180px;
	}
	.statusTable > ul > li:nth-child(3), 
	.statusTable > ul > li:nth-child(4),
	.unitRegListTable > ul > li:nth-child(3),
	.unitRegListTable > ul > li:nth-child(4),
	.unitRegCList > ul > li:nth-child(3),
	.unitRegCList > ul > li:nth-child(4) {
		width: 160px;
	}
	.unitRegListTable > ul > li:nth-child(8),
	.unitRegListTable > ul > li:nth-child(9) {
		width:130px;  
	}  
	.SWlistUnitsTable > ul > li:nth-child(8), 
	.SWlistUnitsTable > ul > li:nth-child(9) {
		width: 13%;
	}
	.statusTable > ul > li:nth-child(6),
	.statusTable > ul > li:nth-child(7){
		display: none;
	}
}
@media only screen and (min-width: 1600px) and (max-width: 1800px) {  
	.perMonthWidget .dayList{
		gap: 12px;
	}
	.brandsList > ul > li {
		width: 242px; 
	} 
	.stLocationTable > ul > li:nth-child(2) p { 
		width:660px;  
	} 
	.bakingPlansList > ul > li:nth-child(2), 
	.bakingPlansList > ul > li:nth-child(3),
	.bakingPlansList > ul > li:nth-child(4),
	.mediaStatus > ul > li:nth-child(3),
	.mediaStatus > ul > li:nth-child(4),
	.mediaStatus > ul > li:nth-child(5),
	.mediaStatus > ul > li:nth-child(7) {
		width: 320px;
	} 
	.menuLbryMcfTable > ul > li:nth-child(5),
	.STComfmtList > ul > li:nth-child(2),
	.STComfmtList > ul > li:nth-child(3) {
		width: 260px;
	}
	.updateStatusTable > ul > li:nth-child(3),
	.updateStatusTable > ul > li:nth-child(4),
	.updateStatusTable > ul > li:nth-child(5),
	.updateStatusTable > ul > li:nth-child(6), 
	.mediaPreparationTable > ul > li:nth-child(3),
	.mediaScreensaverTable > ul > li:nth-child(3),
	.mediaCompilationTable > ul > li:nth-child(3) {
		width: 240px;
	} 
	.unitRegListTable > ul > li:nth-child(7),
	.unitRegCList > ul > li:nth-child(7),
	.menuAStatusTable > ul > li:nth-child(9),
	.STComfmtList > ul > li:nth-child(4),
	.userMgmtListTable > ul > li:nth-child(3),
	.userMgmtListTable > ul > li:nth-child(4),
	.SWlistUnitsTable > ul > li:nth-child(6), 
	.SWlistUnitsTable > ul > li:nth-child(7),
	.updateStatusTable > ul > li:nth-child(4), 
	.updateStatusTable > ul > li:nth-child(5),
	.updateStatusTable > ul > li:nth-child(6),
	.updateStatusTable > ul > li:nth-child(7), 
	.SWlistUnitsTable > ul > li:nth-child(8), 
	.SWlistUnitsTable > ul > li:nth-child(9) {
		width:210px;
	}
	.menuAStatusTable > ul > li:nth-child(7),
	.unitRegListTable > ul > li:nth-child(5),
	.unitRegListTable > ul > li:nth-child(6),
	.unitRegCList > ul > li:nth-child(5),
	.unitRegCList > ul > li:nth-child(6) {
		width:200px;
	}   
	.menuLibraryTable > ul > li:nth-child(4),
	.menuLibraryTable > ul > li:nth-child(5),
	.menuLbryMcfTable > ul > li:nth-child(4), 
	.menuAStatusTable > ul > li:nth-child(4),
	.menuAStatusTable > ul > li:nth-child(5),
	.menuAStatusTable > ul > li:nth-child(6),
	.statusTable > ul > li:nth-child(5),
	.statusTable > ul > li:nth-child(8){
		width: 180px;
	} 
	.userMgmtListTable > ul > li:nth-child(7),
	.updateStatusTable > ul > li:nth-child(8), 
	.menuLbryMcfTable > ul > li:nth-child(9),  
	.menuLibraryTable > ul > li:nth-child(9), 
	.menuLibraryTable > ul > li:nth-child(10),
	.unitRegListTable > ul > li:nth-child(8),
	.unitRegListTable > ul > li:nth-child(9),
	.statusTable > ul > li:nth-child(6),
	.statusTable > ul > li:nth-child(3), 
	.statusTable > ul > li:nth-child(4),
	.mediaPreparationTable > ul > li:nth-child(5),
	.mediaScreensaverTable > ul > li:nth-child(5),
	.mediaCompilationTable > ul > li:nth-child(5),
	.mediaPreparationTable > ul > li:nth-child(6),
	.mediaScreensaverTable > ul > li:nth-child(6),
	.mediaCompilationTable > ul > li:nth-child(6),
	.mediaPreparationTable > ul > li:nth-child(7),
	.mediaScreensaverTable > ul > li:nth-child(7),
	.mediaCompilationTable > ul > li:nth-child(7),
	.mediaPreparationTable > ul > li:nth-child(10),
	.mediaScreensaverTable > ul > li:nth-child(10),
	.mediaCompilationTable > ul > li:nth-child(10),
	.unitRegListTable > ul > li:nth-child(3),
	.unitRegListTable > ul > li:nth-child(4),
	.unitRegCList > ul > li:nth-child(3),
	.unitRegCList > ul > li:nth-child(4) {
		width: 160px;
	}  
	.SWlistUnitsTable > ul > li:nth-child(4), 
	.SWlistUnitsTable > ul > li:nth-child(5){
		width:150px;
	} 
	.updateStatusTable > ul > li:nth-child(9){
		width: 140px;  
	}    
	.userMgmtListTable > ul > li:nth-child(8){
		width:130px;
	} 
	.statusTable > ul > li:nth-child(7){
		width:120px;
	}  
	.menuAStatusTable > ul > li:nth-child(10),
	.menuAStatusTable > ul > li:nth-child(11) {
		width: 100px;
	} 
	.menuLibraryTable > ul > li:nth-child(7) {
		width: 20%;
	}  
	.menuLbryMcfTable > ul > li:nth-child(7) {
		width: 16%;
	} 
	.menuAStatusTable > ul > li:nth-child(8) {
		width: auto;
	} 
}
@media only screen and (min-width: 1801px) and (max-width: 1920px) {  
	.perMonthWidget .dayList{
		gap: 20px;
	}
	.brandsList > ul > li {
		width: 260px; 
	} 
	.stLocationTable > ul > li:nth-child(2) p { 
		width:720px;  
	} 
	.bakingPlansList > ul > li:nth-child(2), 
	.bakingPlansList > ul > li:nth-child(3),
	.bakingPlansList > ul > li:nth-child(4),
	.mediaStatus > ul > li:nth-child(3),
	.mediaStatus > ul > li:nth-child(4),
	.mediaStatus > ul > li:nth-child(5),
	.mediaStatus > ul > li:nth-child(7) {
		width: 340px;
	} 
	.menuLbryMcfTable > ul > li:nth-child(5),
	.STComfmtList > ul > li:nth-child(2),
	.STComfmtList > ul > li:nth-child(3) {
		width: 280px;
	}
	.updateStatusTable > ul > li:nth-child(3),
	.updateStatusTable > ul > li:nth-child(4),
	.updateStatusTable > ul > li:nth-child(5),
	.updateStatusTable > ul > li:nth-child(6), 
	.mediaPreparationTable > ul > li:nth-child(3),
	.mediaScreensaverTable > ul > li:nth-child(3),
	.mediaCompilationTable > ul > li:nth-child(3) {
		width: 260px;
	} 
	.unitRegListTable > ul > li:nth-child(7),
	.unitRegCList > ul > li:nth-child(7),
	.menuAStatusTable > ul > li:nth-child(9),
	.STComfmtList > ul > li:nth-child(4),
	.userMgmtListTable > ul > li:nth-child(3),
	.userMgmtListTable > ul > li:nth-child(4),
	.SWlistUnitsTable > ul > li:nth-child(6), 
	.SWlistUnitsTable > ul > li:nth-child(7),
	.updateStatusTable > ul > li:nth-child(4), 
	.updateStatusTable > ul > li:nth-child(5),
	.updateStatusTable > ul > li:nth-child(6),
	.updateStatusTable > ul > li:nth-child(7), 
	.SWlistUnitsTable > ul > li:nth-child(8), 
	.SWlistUnitsTable > ul > li:nth-child(9) {
		width:220px;
	}
	.menuAStatusTable > ul > li:nth-child(7),
	.unitRegListTable > ul > li:nth-child(5),
	.unitRegListTable > ul > li:nth-child(6),
	.unitRegCList > ul > li:nth-child(5),
	.unitRegCList > ul > li:nth-child(6) {
		width:200px;
	}   
	.menuLibraryTable > ul > li:nth-child(4),
	.menuLibraryTable > ul > li:nth-child(5),
	.menuLbryMcfTable > ul > li:nth-child(4), 
	.menuAStatusTable > ul > li:nth-child(4),
	.menuAStatusTable > ul > li:nth-child(5),
	.menuAStatusTable > ul > li:nth-child(6),
	.statusTable > ul > li:nth-child(5),
	.statusTable > ul > li:nth-child(8){
		width: 180px;
	} 
	.userMgmtListTable > ul > li:nth-child(7),
	.updateStatusTable > ul > li:nth-child(8), 
	.menuLbryMcfTable > ul > li:nth-child(9), 
	.menuLibraryTable > ul > li:nth-child(9), 
	.menuLibraryTable > ul > li:nth-child(10),
	.unitRegListTable > ul > li:nth-child(8),
	.unitRegListTable > ul > li:nth-child(9),
	.statusTable > ul > li:nth-child(6),
	.statusTable > ul > li:nth-child(3), 
	.statusTable > ul > li:nth-child(4),
	.mediaPreparationTable > ul > li:nth-child(5),
	.mediaScreensaverTable > ul > li:nth-child(5),
	.mediaCompilationTable > ul > li:nth-child(5),
	.mediaPreparationTable > ul > li:nth-child(6),
	.mediaScreensaverTable > ul > li:nth-child(6),
	.mediaCompilationTable > ul > li:nth-child(6),
	.mediaPreparationTable > ul > li:nth-child(7),
	.mediaScreensaverTable > ul > li:nth-child(7),
	.mediaCompilationTable > ul > li:nth-child(7),
	.mediaPreparationTable > ul > li:nth-child(10),
	.mediaScreensaverTable > ul > li:nth-child(10),
	.mediaCompilationTable > ul > li:nth-child(10),
	.unitRegListTable > ul > li:nth-child(3),
	.unitRegListTable > ul > li:nth-child(4),
	.unitRegCList > ul > li:nth-child(3),
	.unitRegCList > ul > li:nth-child(4) {
		width: 160px;
	}  
	.SWlistUnitsTable > ul > li:nth-child(4), 
	.SWlistUnitsTable > ul > li:nth-child(5){
		width:150px;
	} 
	.updateStatusTable > ul > li:nth-child(9){
		width: 140px;  
	}    
	.userMgmtListTable > ul > li:nth-child(8){
		width:130px;
	} 
	.statusTable > ul > li:nth-child(7){
		width:120px;
	}  
	.menuAStatusTable > ul > li:nth-child(10),
	.menuAStatusTable > ul > li:nth-child(11) {
		width: 100px;
	} 
	.menuLibraryTable > ul > li:nth-child(7),
	.menuLbryMcfTable > ul > li:nth-child(7) {
		width: 20%;
	} 
	.menuAStatusTable > ul > li:nth-child(8) {
		width: auto;
	} 
}
 
@media only screen and (min-height: 310px) and (max-height: 520px) { 
	.selectLiberaryModal .reBodyWrapper{
		height: 210px;
	}
	.existingLoginForm > ul > li.marginBottom10px {
		margin-bottom:0px;
	}
	.welcomText { 
		font-size: 22px;
	}
	.loginButton > ul > li .loginIWrapper {
		width: 370px;
	}
	.modal-dialog.modal-md {
		width: 370px;
		margin: 5px auto;
	}
	.loginButton > ul > li .existingSingINBtn,
	.loginButton > ul > li .singINBtn {
		padding: 12px 10px;
		font-size: 18px;
	}
	.navbar .welbiltLogo {
		margin: 2px 15px 2px;
	}
}
@media only screen and (min-height: 380px) and (max-height: 520px) {
	body.loginBg {
		background: #0d3968 url(../images/Keyvisual.png) no-repeat center center;
		background-size: 65% auto;
	}
}
@media only screen and (min-height: 330px) and (max-height: 379px) {
	body.loginBg {
		background: #0d3968 url(../images/Keyvisual.png) no-repeat center center;
		background-size: auto 300px;
	}
}
@media only screen and (min-height: 310px) and (max-height: 329px) {
	body.loginBg {
		background: #0d3968 url(../images/Keyvisual.png) no-repeat center center;
		background-size: auto 190px;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
	.amcharts-Button-group {
		display: none !important;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
	.amcharts-Button-group {
		display: none !important;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
	.amcharts-Button-group {
		display: none !important;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 3) {
	.amcharts-Button-group {
		display: none !important;
	}
} 