#connectionFailed,
#connectionNRegistered,
#connectionSuccessful {
  display: none;
}

#scheduledRecipientsEmail,
#scheduledComment,
#ScheduleReportView {
  display: none;
}

#fryerCooksCompletedBarChartdiv,
#FiltersBypassedBarChartdiv {
  width: 100%;
  height: 420px;
  min-height: 420px;
  float: left;
}

#errorsStatuschartdivL,
#connectivityStatuschartdivL {
  width: 40%;
  height: 140px;
  float: left;
}

#slotTimelinechart {
  width: 100%;
  height: 500px;
  float: left;
  margin-top: 10px;
}

#slotTimelinechart .amcharts-chart-div a {
  display: none !important;
}

.recipeEditorWrapper.deActiveED .cbcategory.menuEditActive>ul>li>.cbcategoryBox .cancelButton,
.recipeEditorWrapper.deActiveED .cbcategory>ul>li .cbcategoryAction {
  display: none;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  background-color: black !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  margin: 10px !important;
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
  background-color: gray !important;
}

.leaflet-control-zoom-in::after,
.leaflet-control-zoom-out::after {
  content: '';
  display: block;
  width: 100%;
  height: 0px;
  background-color: transparent !important;
}

.leaflet-control-zoom{
  border: 0px !important;
}